import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Select from 'react-select';
import { UserServices } from "../../services";
import { CATEGORY_TYPES } from "../../config/constant";
import Loader from '../common/Loader';
function ReviewRatings() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const { pageId } = useParams();
    useEffect(() => {
        getReviewList();
    }, [pageId])
    const getReviewList = async () => {
        await UserServices.reviewList(pageId).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                setReviews(data.list);
            }
            else {
                toast.error(message);
            }
        })
    };
    const changeStatus = (evnt, brandId) => { }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span>Product Review & Ratings
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>User</th>
                                                {pageId == 'product' ? <th>Product</th> : <th>Vender</th>}
                                                <th>Rate</th>
                                                <th>Comments</th>
                                                <th>Rate Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reviews && reviews.map((review, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>{review.user ? (review.user.firstName + ' ' + review.user.lastName) : ""}</td>
                                                        {pageId == 'product' ? <td>{review.product ? (review.product.title) : ""}</td> :
                                                            <td>{review.product ? (review.product.title) : ""}</td>}
                                                        <td>{review.rate}</td>
                                                        <td>{review.comments}</td>
                                                        <td>{review.rateDate}</td>
                                                        <td>
                                                            {
                                                                review.approvedStatus == 'pending' ?
                                                                    <label class="badge badge-gradient-warning">Pending</label> :
                                                                    (review.approvedStatus == 'approved' ?
                                                                        <label class="badge badge-gradient-success">Accepted</label> :
                                                                        <label class="badge badge-gradient-danger">Reject</label>)
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReviewRatings;