import { useState } from "react";
import { Link } from "react-router-dom";
import vfilePATH from "../../webroot/sample-file/vendor.xls";
import UploadFile from '../element/UploadFile';
function UserSearch({ total, searchFrom, addURL, filterData, inputHandler, searchFromSubmit, from, filePATH = vfilePATH }) {
    const [exportModal, setExportModal] = useState(false);
    const openModal = () => {
        setExportModal(true);
        document.body.classList.add('modal-open');
    }
    const closeModal = () => {
        setExportModal(false);
        document.body.classList.remove('modal-open')
    }
    const onSuccess = () => {
        closeModal();
    }
    return (
        <div className="col-lg-12 grid-margin-sm">
            <div className="card card-box">
                <div className="card-body card-body-pd">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="total-container">
                                <div className="total-content">
                                    <p>Total {searchFrom}</p>
                                    <h4 className="text-value">{total}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <div className="controls">
                                    <div className="form-group">
                                        <label htmlFor="">Search by name or email or mobile no</label>
                                        <div className="input-group">
                                            <input className="form-control"
                                                type="text"
                                                placeholder="Search by  name or email or mobile no"
                                                value={filterData.search || ""}
                                                name='search'
                                                onChange={inputHandler}
                                            />
                                            <span className="input-group-append">
                                                <button type="button" onClick={searchFromSubmit}
                                                    className="btn btn-gradient-info btn-rounded btn-sm"
                                                    value="submit">Search</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div class="d-flex">
                                <Link to={addURL} data-toggle="tooltip" title="Add User">
                                    <button type="button" class="btn btn-gradient-success btn-rounded btn-icon">
                                        <i class="mdi mdi-account-plus"></i>
                                    </button>
                                </Link>
                                <a href={filePATH} data-toggle="tooltip" title="Download sample fiel" download ><button type="button" class="btn btn-gradient-dark btn-rounded btn-icon">
                                    <i class="mdi mdi-cloud-download"></i>
                                </button></a>
                                <button data-toggle="tooltip" title="Import excel file" onClick={() => openModal()} type="button" class="btn btn-gradient-danger btn-rounded btn-icon">
                                    <i class="mdi mdi-upload btn-icon-prepend"></i>
                                </button>
                            </div>
                            <UploadFile show={exportModal} filePATH={filePATH} onSuccess={onSuccess} from={from} closeModal={closeModal} title={'Import User Excel'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSearch;