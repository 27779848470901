import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { UserServices } from "../../services";
import { Accordion } from "react-bootstrap";
import InnerHtml from "../element/InnerHtml";
import Loader from '../common/Loader';
import UploadFile from "../element/UploadFile";
import filePATH from "../../webroot/sample-file/faqexcel.xls";
function FaqsListComponent() {
    const [isLoading, setIsLoading] = useState(true);
    const [faqsList, setFaqsList] = useState([]);
    useEffect(() => {
        getAllFAQs();
    }, [])
    const getAllFAQs = () => {
        UserServices.faqsList().then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setFaqsList(data.faqs);
                setIsLoading(false);
            }
        })
    }
    const [exportModal, setExportModal] = useState(false);
    const openModal = () => {
        setExportModal(true);
        document.body.classList.add('modal-open');
    }
    const closeModal = () => {
        setExportModal(false);
        document.body.classList.remove('modal-open')
    }
    const onSuccess = () => {
        closeModal();
        getAllFAQs();
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> FAQs
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <UploadFile show={exportModal} filePATH={filePATH} onSuccess={onSuccess} from={"faq"} closeModal={closeModal} title={'Import FAQs Excel'} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-header">
                                <div className="float-right">
                                    <a class="btn btn-gradient-dark btn-sm btn-icon-text m-r-10" href={filePATH} download><i class="mdi mdi-cloud-download"></i> Download Sample File</a>
                                    <button onClick={() => openModal()} type="button" class="btn btn-gradient-primary btn-sm btn-icon-text m-r-10">
                                        <i class="mdi mdi-upload btn-icon-prepend"></i> Upload Excel
                                    </button>
                                    <Link to="/faqs/add">
                                        <button type="button" class="btn btn-gradient-success btn-sm btn-icon-text">
                                            <i class="mdi mdi-message-plus"></i> Add FAQs
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body card-body-pd">
                                <Accordion defaultActiveKey="0">
                                    {faqsList && faqsList.map((faq, idx) => {
                                        return (
                                            <Accordion.Item eventKey={idx}>
                                                <Accordion.Header>
                                                    Que {idx + 1}#
                                                    {faq.question}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        <InnerHtml rawHTML={faq.answer} />
                                                    </div>
                                                    <div className="btn-group-vertical" role="group" aria-label="Basic example">
                                                        <Link to={`/faqs/update/${faq._id}`}>
                                                            <i className="mdi mdi-lead-pencil"></i>
                                                        </Link>
                                                        {faq.status ? <i className="mdi mdi-check-decagram"></i> : <i className="mdi mdi-close-circle-outline"></i>}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqsListComponent;