function UserSellerProfile({ userProf }) {
    return (
        <>
            <div className="border-bottom text-center pb-4">
                <img src={userProf.avatar ? userProf.avatar : require("../../webroot/images/badge1.png").default} alt="profile" className="img-lg rounded-circle mb-3" />
                <p>{userProf.about}</p>
                <h4>{userProf.firstName} {userProf.lastName}</h4>
                <div className="br-widget font-size-25">
                    <a data-rating-value="1" data-rating-text="1" className={userProf.averageRating >= 1 ? "br-selected" : ''}></a>
                    <a data-rating-value="2" data-rating-text="2" className={userProf.averageRating >= 2 ? "br-selected" : ''}></a>
                    <a data-rating-value="3" data-rating-text="3" className={userProf.averageRating >= 3 ? "br-selected" : ''}></a>
                    <a data-rating-value="4" data-rating-text="4" className={userProf.averageRating >= 4 ? "br-selected" : ''}></a>
                    <a data-rating-value="5" data-rating-text="5" className={userProf.averageRating > 4 ? "br-selected" : ''}></a>
                </div>
                <div className="d-flex-1 justify-content-between">
                    {userProf.status == true ?
                        <button className="btn btn-gradient-success">Active</button> :
                        <button className="btn btn-gradient-danger">Inactive</button>
                    }
                </div>
            </div>
            {
                userProf.role === 'vendor' ?
                    <div className="border-bottom py-4">
                        <p>Categories</p>
                        <div>
                            {userProf.categories && (userProf.categories).map((cat, indx) => {
                                return (
                                    <label key={indx} className="badge badge-outline-dark">{cat.title}</label>
                                )
                            })}
                        </div>
                    </div> : null
            }

            <div className="py-4">
                <p className="clearfix">
                    <span className="float-left"> Mobile </span>
                    <span className="float-right text-muted"> +{userProf.dialCode} {userProf.mobile} </span>
                </p>
                <p className="clearfix">
                    <span className="float-left"> Mail </span>
                    <span className="float-right text-muted"> {userProf.email} </span>
                </p>
                <p className="clearfix">
                    <span className="float-left"> Facebook </span>
                    <span className="float-right text-muted"><a href="#">{userProf.facebook}</a></span>
                </p>
                <p className="clearfix">
                    <span className="float-left"> Gmail </span>
                    <span className="float-right text-muted"><a href="#">{userProf.gmail}</a></span>
                </p>
                <p className="clearfix">
                    <span className="float-left"> Pin Code </span>
                    <span className="float-right text-muted"> {userProf.address ? userProf.address.postcode : ""} </span>
                </p>
                <p className="clearfix">
                    <span className="float-left"> Address </span>
                    <span className="float-right text-muted"> {userProf.address ? userProf.address.postalAddress : ""} </span>
                </p>
                <p className="clearfix">
                    <span className="float-left"> City </span>
                    <span className="float-right text-muted"> {userProf.address ? userProf.address.city : ""} </span>
                </p>
                <p className="clearfix">
                    <span className="float-left"> State </span>
                    <span className="float-right text-muted"> {userProf.address ? userProf.address.state : ""} </span>
                </p>
                <p className="clearfix">
                    <span className="float-left"> Country </span>
                    <span className="float-right text-muted"> {userProf.address ? userProf.address.country : ""} </span>
                </p>
            </div>
        </>
    )
}
export default UserSellerProfile;