import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Select from 'react-select';
import { UserServices } from "../../services";
import { CATEGORY_TYPES } from "../../config/constant";
import Loader from '../common/Loader';
import BreadCrumb from '../common/BreadCrumb';
function ProductInCart() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    useEffect(() => {
    }, [])
    let { categoryId } = useParams();
    const getProductList = async () => {
        await UserServices.productAdminList({}).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
               
            }
            else {
                toast.error(message);
            }
        })
    };
    const changeStatus = (evnt, brandId) => { }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <BreadCrumb title={'Product in user carts'} />
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>User</th>
                                                <th>In Cart</th>
                                                <th>Product</th>
                                                <th>Original Price</th>
                                                <th>Selling Price</th>
                                                <th>Product Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products && products.map((btype, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td className="td-grid-profile">
                                                            <img src={''} className="mr-2" />
                                                            <div class="nav-profile-text d-flex flex-column">
                                                                <a class="cursor-pointer font-weight-bold mb-1 mt-1">
                                                                    {btype.title}
                                                                </a>
                                                                <span class="text-secondary text-small">{btype.uniqueId}</span>
                                                            </div>
                                                        </td>
                                                        <td>{btype.uniqueId}</td>
                                                        <td>{btype.currency} {btype.originalPrice}</td>
                                                        <td>{btype.currency} {btype.sellingPrice}</td>
                                                        <td>{btype.totalUnits}</td>
                                                        <td>{btype.soldUnits}</td>
                                                        <td>{btype.vender ? (btype.vender.firstName + ' ' + btype.vender.lastName) : ""}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductInCart;