function Modal({ show, handleClose, children, title = "Modal title" }) {
    let toggleModalClass = show ? 'modal fade show d-block' : 'modal fade d-none';
    return (
        <div className={toggleModalClass} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="close" onClick={handleClose} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Modal;