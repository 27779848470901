function BreadCrumb(props) {
    return (
        <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-info text-white mr-2">
                    <i className="mdi mdi-home"></i>
                </span>{props.title || ""}
            </h3>
            <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                        <span></span>Overview <i
                            className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
export default BreadCrumb;