import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import plugins from "suneditor/src/plugins";

export const TextEditor = ({ initialContent = "", onChange, name }) => {
    let inputHandler = (txt) => {
        onChange(name, txt, true);
    }
    return (
        <SunEditor
            width="100%"
            height="150px"
            setOptions={{
                showPathLabel: false,
                minHeight: "50vh",
                maxHeight: "50vh",
                placeholder: "Type something...",
                requestHeaders: {
                    "X-Sample": "sample"
                },
                imageGalleryLoadURL: "https://fake-images.glitch.me?limit=100",
                plugins: plugins,
                buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["paragraphStyle", "blockquote"],
                    [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript"
                    ],
                    ["fontColor", "hiliteColor", "textStyle"],
                    ["removeFormat"],
                    "/", // Line break
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["table", "link", "image", "video", "audio" /** ,'math' */],
                    ['imageGallery', "fullScreen", "showBlocks", "codeView"],
                    ["preview", "print"],
                    ["save", "template"]
                ]
            }}
            setContents={initialContent}
            defaultValue={initialContent}
            onChange={inputHandler}
            name={name}
        />
    );
};