import secureStorage from "../config/encrypt";
import { API_URL } from "../config/constant";
import dateFormat from "dateformat";
export const isLogin = () => {
    let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY)
    if (user && user.user) {
        return true;
    }
    return false;
}
export const logout = () => {
    secureStorage.removeItem(API_URL.COMMON_LOGIN_STORE_KEY)
    return true;
}
export const loginUserId = () => {
    let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY)
    if (user && user.user) {
        return user.user._id;
    }
    return null;
}
export const TEXT = (txt, limit = 10) => {
    return txt.length > limit ? txt.slice(0, limit) + ' ...' : txt;
}
export const displayDate = (d, f = 'dt') => {
    return f == 'dt' ? dateFormat(d, 'd mmm yyyy h:MM:ss TT') : dateFormat(d, 'd mmm yyyy');
}