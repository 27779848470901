import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from '../common/Loader';
import { useHistory, useParams } from 'react-router-dom';
import UploadFile from "../element/UploadFile";
import filePATH from "../../webroot/sample-file/catagory.csv";
import Pagination from '../element/Pagintion';
function ProductCategoryListComponent() {
    const [categories, setCategories] = useState([]);
    const [totalCategory, setTotalCategory] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    let { type } = useParams();
    // const [inputField, setInputField] = useState({
    //     title: ""
    // });
    useEffect(() => {
        if (type) {
            getAllCategories(type);
        }
    }, [type])
    const [exportModal, setExportModal] = useState(false);
    const openModal = () => {
        setExportModal(true);
        document.body.classList.add('modal-open');
    }
    const closeModal = () => {
        setExportModal(false);
        document.body.classList.remove('modal-open')
    }
    const onSuccess = () => {
        closeModal();
        getAllCategories(type, page);
    }
    const getAllCategories = (type, pageNo = page) => {
        UserServices.categoriesList(type, pageNo).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setCategories(data.list);
                setTotalCategory(data.total);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const changeStatus = (evnt, categoryId) => {
        let obj = {
            categoryId: categoryId,
            status: evnt.target.checked
        }
        UserServices.updateCategoryStatus(obj).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                toast.success(message);
                getAllCategories();
            }
            else {
                toast.error(message);
            }
        })
    }
    const handlePageClick = (n) => { setPage(n); getAllCategories(type, n); }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> {type} Category
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-header">
                                <div className="float-right">
                                    <a className="btn btn-gradient-dark btn-sm btn-icon-text m-r-10" href={filePATH} download><i className="mdi mdi-cloud-download"></i> Download Sample File</a>
                                    <UploadFile filePATH={filePATH} show={exportModal} onSuccess={onSuccess} from={"category"} closeModal={closeModal} title={'Import Category Excel'} />
                                    <button type="button" onClick={() => openModal()} className="btn btn-gradient-primary btn-sm btn-icon-text m-r-10">
                                        <i className="mdi mdi-upload btn-icon-prepend"></i> Upload Excel
                                    </button>
                                    <Link to={`/${type}/category/add`}>
                                        <button type="button" className="btn btn-gradient-success btn-sm btn-icon-text">
                                            <i className="mdi mdi-message-plus"></i> Add
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>Icon</th>
                                                <th>Type</th>
                                                <th>Title</th>
                                                <th>Slug</th>
                                                <th>Parent</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categories && categories.map((btype, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>
                                                            <img src={btype.iconImg} />
                                                        </td>
                                                        <td>{btype.type}</td>
                                                        <td>{btype.title}</td>
                                                        <td>{btype.slug}</td>
                                                        <td>{btype.parentId ? (btype.parentId ? btype.parentId.title : "") : '---'}</td>
                                                        <td>
                                                            {btype.status == true ?
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id)} className="switch-input" checked />
                                                                    <span className="switch-slider"></span>
                                                                </label> :
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id)} className="switch-input" />
                                                                    <span className="switch-slider"></span>
                                                                </label>
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                                <Link to={`/product/category/update/${btype._id}`} className="btn btn-gradient-primary btn-sm">
                                                                    <i className="mdi mdi-lead-pencil"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination-container container">
                                    <Pagination onPaginate={handlePageClick} total={totalCategory} perPage={50} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCategoryListComponent;