import { useState } from "react";
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Modal from "./Modal";
function UploadFile(props) {
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const inputHandler = (e) => {
        if (e.target.files) {
            setSelectedFile(e.target.files[0]);
            setIsFilePicked(true);
        }
    }
    const submitForm = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('role', props.from);
        //         router.post('/category', impFileController.importCategory);
        // router.post('/product', impFileController.importProduct);
        // router.post('/brand', impFileController.importBrand);
        // router.post('/faq',
        if (isFilePicked) {
            UserServices.importExcelFile(formData, props.from).then((responce) => {
                let { message, statusCode } = responce.data;
                if (statusCode == 200) {
                    toast.success(message);
                    props.closeModal();
                    window.location.reload();
                }
                else {
                    toast.error(message);
                }
            })
        }
    }
    return (
        <Modal show={props.show} handleClose={() => { props.closeModal() }} title={props.title}>
            <div className="import-container">
                <a href={props.filePATH} download>Download sample file</a>
                <div class="upload-form-container">
                    <div class="upload-form-containt">
                        <input type="file" onChange={inputHandler} className="upload-input" />
                    </div>
                </div>
                <div className="float-right">
                    {/* <button type="button" class="btn btn-gradient-light btn-sw m-r-10">Close</button> */}
                    <button type="button" onClick={submitForm} class="btn btn-gradient-info btn-sw"><i class="mdi mdi-upload btn-icon-prepend"></i> Upload</button>
                </div>
            </div>
        </Modal>
    )
}
export default UploadFile;