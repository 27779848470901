import { useState } from "react";
import BreadCrumb from "../common/BreadCrumb";
import Loader from "../common/Loader";

function PackageList() {
    let [isLoading, setIsLoading] = useState();
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <BreadCrumb title={'Package List'} />
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>Type</th>
                                                <th>Paid Status</th>
                                                <th>Free Trial</th>
                                                <th>Free Trial Days / Months</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PackageList;