import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { useState } from "react";
import { toast } from "react-toastify"
import { useHistory } from "react-router";
import { isLogin, logout } from "../../utils"
import { Link } from "react-router-dom";
function Header() {
  const history = useHistory();
  const [userDetail, setUserDetail] = useState({})
  // useEffect(() => {
  //   const loggedInUser = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
  //   if (loggedInUser && loggedInUser.user) {
  //     setUserDetail(loggedInUser.user);
  //     // if (history) history.push('/home');
  //   }
  // })
  const logOut = (e) => {
    if (isLogin()) {
      logout();
      toast.success("Logout successfully");
      if (history) history.push('/login');
    }
  }
  return (
    <nav className="nav-box navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to={"/"}><img src={require("../../webroot/images/logo.png").default} alt="logo" /></Link>
        <Link className="navbar-brand brand-logo-mini" to={"/"}><img src={require("../../webroot/images/logo.png").default} alt="logo" /></Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button id="navbar-toggler-rao" className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span className="mdi mdi-menu"></span>
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown">
            <a className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
              <div className="nav-profile-img">
                <img src={require("../../webroot/images/badge1.png").default} alt="image" />
                <span className="availability-status online"></span>
              </div>
              <div className="nav-profile-text">
                <p className="mb-1 text-black">Admin</p>
              </div>
            </a>
            <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
              <Link className="dropdown-item" to={'/profile'}>
                <i className="mdi mdi-cached mr-2 text-success"></i> Profile </Link>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#" onClick={logOut}>
                <i className="mdi mdi-logout mr-2 text-primary"></i> Signout </a>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
              <i className="mdi mdi-bell-outline"></i>
              <span className="count-symbol bg-danger"></span>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
              <h6 className="p-3 mb-0">Notifications</h6>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-success">
                    <i className="mdi mdi-calendar"></i>
                  </div>
                </div>
                <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                  <h6 className="preview-subject font-weight-normal mb-1">Event today</h6>
                  <p className="text-gray ellipsis mb-0"> Just a reminder that you have an event today </p>
                </div>
              </a>
            </div>
          </li>
          <li className="nav-item nav-logout d-none d-lg-block">
            <a onClick={logOut} className="nav-link">
              <i className="mdi mdi-power"></i>
            </a>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}

export default Header;