import { useEffect, useState } from "react";
import Select from 'react-select';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from "../common/Loader";
import { Calendra } from "../element/CalendarPicker";
import { useParams, useHistory } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
const prodObj = {
    title: "",
    modelNumber: "",
    partNumber: "",
    manufacturer: "",
    brand: "",
    totalUnits: 0,
    countries: [],
    releaseDate: "",
    expiryDate: "",
    sellingPrice: "",
    originalPrice: "",
    conditions: "",
    description: "",
    categories: "",
    categories2: "",
    categories3: "",
    images: [],
}
const setProductImagesArr = [
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" }
]
function ManageProduct({ userId, userData }) {
    const [productForm, setProductForm] = useState(prodObj);
    const [manufacturerArr, setManufacturerArr] = useState([]);
    const [brandsArr, setBrandsArr] = useState([]);
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [subcategoriesArr, setSubcategoriesArr] = useState([]);
    const [subcategoriesArr3, setSubcategoriesArr3] = useState([]);
    const [countriesArr, setCountriesArr] = useState([]);
    const [productImages, setProductImages] = useState(setProductImagesArr);
    const [exclproductImages, setExclproductImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [actionType, setActionType] = useState("Add");
    const { productId } = useParams();
    const history = useHistory();
    // console.log(productId);
    useEffect(() => {
        getAllBrands();
        getAllCategories();
        getCountryList();
        // getManufacturerList();
        if (productId !== 'add') {
            getProductDetail();
            setActionType("Update")
        }
    }, [])
    const getProductDetail = () => {
        UserServices.editProduct({ productId }).then((responce) => {
            let { data, message, status } = responce.data;
            if (status == 200) {
                if (data && data.categoryObj) {
                    setSubcategoriesArr(data.categoryObj.subcategories);
                    data.categoryObj.subcategories.map(obj => {
                        if (data.categories2 && data.categories2.toString() == obj.value.toString()) {
                            setSubcategoriesArr3(obj.subcategories3);
                        }
                        return obj;
                    })
                    setExclproductImages(data.imageUrl);
                }
                delete data.categoryObj;
                setProductForm(data);
            }
            else {
                toast.error(message);
            }
        })
    }
    const getManufacturerList = () => {
        UserServices.brandDropdownList('manufactur').then((responce) => {
            let { data, message, status } = responce.data;
            if (status == 200) {
                setManufacturerArr(data);
            }
        })
    }
    const getAllBrands = () => {
        UserServices.brandDropdownList('brand').then((responce) => {
            let { data, status } = responce.data;
            if (status == 200) {
                setBrandsArr(data);
            }
        })
    }
    const getAllCategories = () => {
        UserServices.categoriesDropDownList().then((responce) => {
            let { data, message, status } = responce.data;
            if (status == 200) {
                setCategoriesArr(data.list);
            }
        })
    }
    const getCountryList = () => {
        UserServices.countryList().then((responce) => {
            let { data, message, status } = responce.data;
            if (status == 200) {
                setCountriesArr(data);
            }
        })
    }
    const changeHandler = (e, name = "") => {
        if (name) {
            switch (name) {
                case 'categories':
                    // let categories = e.map(obj => obj.value);
                    setProductForm((prev) => ({
                        ...prev,
                        categories: e.value
                    }))
                    setSubcategoriesArr(e.subcategories);
                    setSubcategoriesArr3([]);
                    break;
                case 'categories2':
                    setProductForm((prev) => ({
                        ...prev,
                        categories2: e.value
                    }))
                    setSubcategoriesArr3(e.subcategories3);
                    break;
                case 'categories3':
                    setProductForm((prev) => ({
                        ...prev,
                        categories3: e.value
                    }))
                    break;
                case 'countries':
                    let countries = e.map(obj => obj.name);
                    setProductForm((prev) => ({
                        ...prev,
                        countries: countries
                    }))
                    break;
                case 'manufacturer':
                    setProductForm((prev) => ({
                        ...prev,
                        manufacturer: e.value
                    }))
                    break;
                case 'brand':
                    setProductForm((prev) => ({
                        ...prev,
                        brand: e.value
                    }))
                    break;
                case 'releaseDate':
                    setProductForm((prev) => ({
                        ...prev,
                        releaseDate: e
                    }))
                    break;
                case 'expiryDate':
                    setProductForm((prev) => ({
                        ...prev,
                        expiryDate: e
                    }))
                    break;
            }
        }
        else {
            setProductForm((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }
    const submitProductForm = (e) => {
        setIsLoading(true);
        if (productId) {
            delete productForm.vender;
            productForm.productId = productId;
            UserServices.updateProduct(productForm).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode == 200) {
                    toast.success(message);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
        else {
            productForm.vender = userId;
            UserServices.addProduct(productForm).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode == 200) {
                    toast.success(message);
                    if (history) history.push('/product/' + data._id);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
    }
    const changeFile = (e, i) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
            productImages[i] = {
                img: reader.result,
                file: e.target.files[0]
            }
        }

    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span>Manage Product
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card card-box">
                            <div className="card-body card-body-pd">
                                <div className="d_boxs  mb-2 mb-md-4">
                                    <ul className="nav custome-nav mb-2 mb-md-3">
                                        <li className="nav-item">
                                            <a className=" active" data-toggle="tab" href="#product">Product</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="" data-toggle="tab" href="#productImage">Product Images </a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="" data-toggle="tab" href="#uploadProducts">Upload Product</a>
                                        </li> */}
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active " id="product">
                                            <div className="row">
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Model Number</label>
                                                    <input type="text" onChange={changeHandler} name="modelNumber" value={productForm.modelNumber} className="form-control" />
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Part Number/ Product ID </label>
                                                    <input type="text" onChange={changeHandler} name="partNumber" value={productForm.partNumber} className="form-control" />
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Product Name</label>
                                                    <input type="text" onChange={changeHandler} name="title" value={productForm.title} className="form-control" />
                                                </div>
                                                {/* <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Manufacturer</label>
                                                    <Select
                                                        onChange={(e) => changeHandler(e, 'manufacturer')} name="manufacturer"
                                                        value={manufacturerArr.filter(obj => productForm.manufacturer == (obj.value))}
                                                        options={manufacturerArr}
                                                        isClearable
                                                    />
                                                </div> */}
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Brand</label>
                                                    <Select
                                                        onChange={(e) => changeHandler(e, 'brand')} name="brand"
                                                        value={brandsArr.filter(obj => productForm.brand == (obj.value))}
                                                        options={brandsArr}
                                                        isClearable
                                                    />
                                                </div>

                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="required"> Category</label>
                                                    <Select
                                                        onChange={(e) => changeHandler(e, 'categories')} name="categories"
                                                        value={categoriesArr.filter(obj => productForm.categories == (obj.value))}
                                                        options={categoriesArr}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label> Category2</label>
                                                    <Select
                                                        onChange={(e) => changeHandler(e, 'categories2')} name="categories2"
                                                        value={subcategoriesArr.filter(obj => productForm.categories2 == (obj.value))}
                                                        options={subcategoriesArr}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label>Category3</label>
                                                    <Select
                                                        onChange={(e) => changeHandler(e, 'categories3')} name="categories3"
                                                        value={subcategoriesArr3.filter(obj => productForm.categories3 == (obj.value))}
                                                        options={subcategoriesArr3}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Country of origin </label>
                                                    <Select
                                                        onChange={(e) => changeHandler(e, 'countries')} name="countries"
                                                        value={countriesArr.filter(obj => productForm.countries.includes(obj.name))}
                                                        options={countriesArr}
                                                        getOptionLabel={option => option.name}
                                                        getOptionValue={option => option.name}
                                                        isClearable
                                                        isMulti
                                                    />
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Item Quantity </label>
                                                    <input type="text" onChange={changeHandler} name="totalUnits" value={productForm.totalUnits} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-3 col-md-3 col-sm-3 mb-md-3">
                                                    <label className="">Release Date</label>
                                                    <Calendra
                                                        className="form-control"
                                                        value={productForm.releaseDate}
                                                        name="releaseDate"
                                                        onChange={changeHandler}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-3 col-md-3 col-sm-3 mb-md-3">
                                                    <label className="">Expiry Date</label>
                                                    <Calendra
                                                        className="form-control"
                                                        value={productForm.expiryDate}
                                                        name="expiryDate"
                                                        onChange={changeHandler}
                                                    />
                                                </div>

                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Listed (Original) Price </label>
                                                    <div className="position_relative_inr">
                                                        <span className="inr_a">INR</span>
                                                        <input type="text" onChange={changeHandler} name="originalPrice" value={productForm.originalPrice} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Promotional (Selling) Price </label>
                                                    <div className="position_relative_inr">
                                                        <span className="inr_a">INR</span>
                                                        <input type="text" onChange={changeHandler} name="sellingPrice" value={productForm.sellingPrice} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-md-4">
                                                <label className="">Condition</label>
                                                <TextareaAutosize minRows={3} className="form-control" onChange={changeHandler} name="conditions" value={productForm.conditions} />
                                            </div>
                                            <div className="form-group mb-md-4">
                                                <label className="">Descriptions</label>
                                                <TextareaAutosize minRows={3} className="form-control" onChange={changeHandler} name="description" value={productForm.description} />
                                            </div>
                                            <button type="button" onClick={submitProductForm} className="btn btn-gradient-primary btn-fw">SUBMIT</button>
                                        </div>
                                        <div className="tab-pane" id="productImage">
                                            {/* <div className="row row2 col5 mb-md-4">
                                                {
                                                    productImages.map((obj, index) => {
                                                        return (
                                                            <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                                                                <div className="add_pravu_item">
                                                                    <a className="add_delite"><i className="fa fa-trash-o"></i>
                                                                    </a>
                                                                    <div className="add_pravu_img">
                                                                        <img src={obj.img} />
                                                                    </div>
                                                                    <label className="rao-file-label">
                                                                        <i className="fa fa-upload"></i>&nbsp;&nbsp;
                                                                        <span>Choose a file…</span>
                                                                        <input type="file" onChange={(e) => changeFile(e, index)} name="profile_pic" className="rao-file-input inputfile" accept="image/*" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> */}
                                            <div className="row row2 col5 mb-md-4">
                                                {
                                                    exclproductImages.map((obj, index) => {
                                                        return (
                                                            <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                                                                <div className="add_pravu_item">
                                                                    <a className="add_delite"><i className="fa fa-trash-o"></i>
                                                                    </a>
                                                                    <div className="add_pravu_img">
                                                                        <img width="200" height="150" src={obj} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="uploadProducts">
                                            {/* <a className="" href={productSample} download><i className="fa fa-download"></i> Download Sample File</a>
                        <div className="row">
                            <div className="form-group col-lg-4 col-md-6 col-sm-6 mb-md-4">
                                <input type="file" className="form-control" />
                            </div>
                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ManageProduct;