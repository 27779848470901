import { Component, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DatePicker from "../element/DatePicker";
import { isLogin, loginUserId } from "../../utils"
let proObj = {
    firstName: "",
    lastName: "",
    mobileNo: "",
    dialCode: "",
    iso2: "",
    email: "",
    profileImage: "",
    temparoryMobileNo: "",
    about: "",
    postalAddress: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    location: ""
};
function UpdateProfile({ loginUser }) {
    const [userForm, setUserForm] = useState(proObj);
    const [selectedFile, setSelectedFile] = useState();
    useEffect(() => {
        if (isLogin()) {
            userDetail(loginUserId());
        }
    }, []);
    const userDetail = async (adminId) => {
        UserServices.adminDetail({ adminId: adminId }).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                let proObj = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    mobileNo: data.mobileNo,
                    dialCode: data.dialCode,
                    iso2: data.iso2,
                    email: data.email,
                    // profileImage: data.firstName,
                    // temparoryMobileNo: data.firstName,
                    about: data.about,
                    postalAddress: (data.address ? data.address.postalAddress : ""),
                    country: (data.address ? data.address.country : ""),
                    state: (data.address ? data.address.state : ""),
                    city: (data.address ? data.address.city : ""),
                    zipCode: (data.address ? data.address.zipCode : ""),
                    location: (data.address ? data.address.location : "")
                };
                setUserForm(proObj)
            }
            else {
                toast.error(message);
            }
        })
    }
    const inputHandler = (e) => {
        if (e.target.files) {
            setSelectedFile(e.target.files[0]);
        }
        else {
            setUserForm({ ...userForm, [e.target.name]: e.target.value });
        }
    }
    let onChangeMobile = (status, value, countryData) => {
        setUserForm((pre) => ({
            ...pre,
            mobileNo: value,
            dialCode: countryData.dialCode,
            iso2: countryData.iso2,
        }))
    }
    const submitForm = async (e) => {
        e.preventDefault();
        userForm.mobileNo = (userForm.mobileNo).replace(" ",'')
        const formData = new FormData();
        formData.append('id', loginUserId());
        formData.append('firstName', userForm.firstName);
        formData.append('lastName', userForm.lastName);
        formData.append('mobileNo', userForm.mobileNo);
        formData.append('dialCode', userForm.dialCode);
        formData.append('iso2', userForm.iso2);
        formData.append('email', userForm.email);
        formData.append('about', userForm.about);
        formData.append('postalAddress', userForm.postalAddress);
        formData.append('country', userForm.country);
        formData.append('state', userForm.state);
        formData.append('city', userForm.city);
        formData.append('zipCode', userForm.zipCode);
        formData.append('location', userForm.location);
        formData.append('profileImage', selectedFile);
        UserServices.profileUpdate(formData).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
        })
    }
    return (
        <>
            <div className="form-horizontal">
                <div className="row">
                    <div className="form-group col-sm-4">
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" name="firstName"
                            value={userForm.firstName}
                            onChange={inputHandler}
                            className="form-control"
                            id="firstName" />
                    </div>
                    <div className="form-group col-sm-4">
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" name="lastName"
                            value={userForm.lastName}
                            onChange={inputHandler}
                            className="form-control" id="lastName" />
                    </div>
                    <div className="form-group col-sm-4">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" value={userForm.email}
                            onChange={inputHandler}
                            className="form-control" id="email" />
                    </div>
                    <div className="form-group col-sm-4">
                        <label htmlFor="mobileNo">Mobile</label>
                        <div className="clearfix"></div>
                        <IntlTelInput
                            value={userForm.mobileNo}
                            containerClassName="intl-tel-input"
                            inputClassName="form-control number"
                            preferredCountries={['in']}
                            onSelectFlag={(num, country) => {
                                console.log('onSelectFlag', country);
                            }}
                            onPhoneNumberChange={onChangeMobile}
                        />
                    </div>
                    <div className="form-group col-sm-4">
                        <label htmlFor="profileImage">Profile Image</label>
                        <input type="file" name="profileImage"
                            onChange={inputHandler}
                            className="form-control" id="profileImage" />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <label htmlFor="about">About</label>
                        <input type="text" name="about"
                            value={userForm.about}
                            onChange={inputHandler}
                            className="form-control" id="about" />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-8">
                        <label htmlFor="address">Address</label>
                        <input type="text" name="postalAddress"
                            value={userForm.postalAddress}
                            onChange={inputHandler}
                            className="form-control" id="postalAddress" />
                    </div>
                    <div className="form-group col-sm-4">
                        <label htmlFor="country">Country</label>
                        <input type="text" name="country"
                            value={userForm.country}
                            onChange={inputHandler}
                            className="form-control" id="country" />
                    </div>
                    <div className="form-group col-sm-4">
                        <label htmlFor="state">State</label>
                        <input type="text" name="state"
                            value={userForm.state}
                            onChange={inputHandler}
                            className="form-control" id="state" />
                    </div>
                    <div className="form-group col-sm-4">
                        <label htmlFor="city">City</label>
                        <input type="text" name="city"
                            value={userForm.city}
                            onChange={inputHandler}
                            className="form-control" id="city" />
                    </div>
                    <div className="form-group col-sm-4">
                        <label htmlFor="zipCode">Postcode</label>
                        <input type="text" name="zipCode"
                            value={userForm.zipCode}
                            onChange={inputHandler}
                            className="form-control" id="zipCode" />
                    </div>
                </div>
            </div>
            <button type="button" onClick={submitForm} className="btn btn-gradient-info btn-fw">Submit</button>
        </>

    );
}

export default UpdateProfile;