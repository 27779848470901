import React from 'react';
import { BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import routes from './routes-data';
import PublicRoute from './public';
import PrivateRoute from './private';
import Header from "../view/common/Header";
import LeftSideBar from "../view/common/LeftSideBar";

const HeaderComp = (props) => {
    const { location } = props;
    return (location.pathname.match(/^\/(login|logout|forgotpassword|'')$/)) ? null : <Header />
};
const HeaderHide = withRouter(HeaderComp);
const LeftSideBarHide = withRouter((props) => {
    const { location } = props;
    return (location.pathname.match(/^\/(login|logout|forgotpassword|'')$/)) ? null : <LeftSideBar />
});
//const basename = process.env.NODE_ENV === 'production' ? '/sst' : '/';
// const basename = '/';///stge
const basename = '/';///live
const Routers = () => (
    <Router basename={basename}>
        <HeaderHide />
        <div className="container-fluid page-body-wrapper">
            <LeftSideBarHide />
            <Switch>
                {routes.map((route, i) => {
                    if (route.auth) {
                        return <PrivateRoute key={i} {...route} />
                    } else {

                        return <PublicRoute key={i} {...route} />
                    }
                })}
            </Switch>
        </div>
    </Router>
);
export default Routers;