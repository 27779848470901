import { Component } from 'react';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import Loader from '../common/Loader';
let formArr = { brand: "", relationship: "" };
class SellerComponent extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.state = {
            businessTypes: [],
            brandsArr: [],
            isOkTermAndCondition: false,
            isLoading: false,
            userForm: {
                companyName: "",
                companyLogo: null,
                companyDocument: null,
                designation: "",
                avatar: null,
                firstName: "",
                lastName: "",
                email: "",
                mobile: "",
                postalAddress: "",
                country: "",
                state: "",
                city: "",
                postcode: "",
                categories: [],
                brands: [formArr]
            }
        }
    }
    componentDidMount = async () => {
        await this.getDetail();
        await this.getAllBrands();
        await this.getAllBusinessTypes();
    }
    getAllBrands() {
        UserServices.brandDropdownList().then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                this.setState({ brandsArr: data });
            }
        })
    }

    getDetail() {
        if (this.props.match.params.sellerId) {
            this.setState({ isLoading: true });
            let { userForm } = this.state;
            UserServices.userGet(this.props.match.params.sellerId).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode === 200) {
                    let { user } = data ? data : {};
                    userForm = {
                        companyName: user.companyName,
                        designation: user.designation,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        dialCode: user.dialCode,
                        iso2: user.iso2,
                        mobile: user.mobile,
                        postalAddress: user.address ? user.address.postalAddress : "",
                        country: user.address ? user.address.country : "",
                        state: user.address ? user.address.state : "",
                        city: user.address ? user.address.city : "",
                        postcode: user.address ? user.address.postcode : "",
                        categories: user.categories,
                        brands: [formArr]
                    }
                    this.setState({ userForm });
                }
                else {
                    toast.error(message);
                }
                this.setState({ isLoading: false });
            })
        }
    }
    getAllBusinessTypes() {
        UserServices.categoriesDropDownList().then((responce) => {
            let { data, message } = responce.data;
            this.setState({ businessTypes: data.list });
        })
    }
    addMoreRow(e) {
        let { userForm } = this.state;
        userForm['brands'] = [...userForm['brands'], ...[formArr]];
        this.setState({ userForm });
    }
    formArrayChange(i, name, e) {
        let { userForm } = this.state;
        let vl = '';
        if (name == 'brand') {
            vl = e.value;
        }
        else {
            vl = e.target.value;
        }
        userForm['brands'] = userForm['brands'].map((brand, index) => {
            if (i == index) {
                brand = { ...brand, ...{ [name]: vl } }
            }
            return brand;
        });
        this.setState({ userForm });
    }
    onChangeMobile(status, value, countryData) {
        let { userForm } = this.state;
        userForm['mobile'] = value;
        userForm['dialCode'] = countryData.dialCode;
        userForm['iso2'] = countryData.iso2;
        this.setState({ userForm });
    }
    handleChange(event, name = '') {
        let { userForm } = this.state;
        if (name != "") {
            userForm.categories = Array.isArray(event) ? event.map(x => x.value) : [];
        }
        else if (event.target.files && event.target.files[0]) {
            userForm[event.target.name] = (event.target.files[0]);
            // URL.createObjectURL
        }
        else {
            userForm[event.target.name] = event.target.value
        }
        this.setState({ userForm });
    }
    validForm() {
        let { userForm } = this.state;
        let isValid = false;
        if (userForm.mobile) {
            isValid = true;
        }
        if (userForm.email) {
            isValid = true;
        }
        return isValid;
    }
    handleSubmit(e) {
        e.preventDefault();
        let { userForm } = this.state;
        if (this.validForm()) {
            this.setState({ isLoading: true });
            const formData = new FormData();
            formData.append('companyName', userForm.companyName);
            formData.append('companyLogo', userForm.companyLogo);
            formData.append('companyDocument', userForm.companyDocument);
            formData.append('avatar', userForm.avatar);
            formData.append('firstName', userForm.firstName);
            formData.append('lastName', userForm.lastName);
            formData.append('email', userForm.email);
            formData.append('mobile', userForm.mobile);
            formData.append('postalAddress', userForm.postalAddress);
            formData.append('country', userForm.country);
            formData.append('state', userForm.state);
            formData.append('city', userForm.city);
            formData.append('postcode', userForm.postcode);
            formData.append('categories', JSON.stringify(userForm.categories));
            formData.append('designation', userForm.designation);
            formData.append('dialCode', userForm.dialCode);
            formData.append('iso2', userForm.iso2);
            formData.append('hearAboutICT', "ADMIN");
            if (this.props.match.params.sellerId) {
                formData.append('userId', this.props.match.params.sellerId);
                delete userForm.password;
                UserServices.updateUser(formData).then((responce) => {
                    let { data, message, statusCode } = responce.data;
                    if (statusCode == 200) {
                        toast.success(message);
                        const { history } = this.props;
                        if (history) history.push('/seller');
                    }
                    else {
                        toast.error(message);
                    }
                    this.setState({ isLoading: false });
                })
            }
            else {
                formData.append('password', userForm.password);
                formData.append('role', 'vendor');
                UserServices.addUser(formData).then((responce) => {
                    let { data, message, statusCode } = responce.data;
                    if (statusCode == 200) {
                        toast.success(message);
                        const { history } = this.props;
                        if (history) history.push('/seller');
                    }
                    else {
                        toast.error(message);
                    }
                    this.setState({ isLoading: false });
                })
            }
        }
        else {
            toast.error('Please fill email and mobile number')
        }
    }
    render() {
        let { userForm, businessTypes, isLoading, brandsArr } = this.state;
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="page-header">
                        <h3 className="page-title">
                            <span className="page-title-icon bg-gradient-info text-white mr-2">
                                <i className="mdi mdi-home"></i>
                            </span> Manage Seller
                        </h3>
                        <nav aria-label="breadcrumb">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span></span>Overview <i
                                        className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <Loader isLoading={isLoading} />
                    <div className="row">
                        <div className="col-lg-12 grid-margin">
                            <div className="card card-box">
                                <div className="card-body card-body-pd">
                                    <div className="form-horizontal">
                                        <h4 className="card-title">Personal Information</h4>
                                        <div className="row">
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="firstName">First Name</label>
                                                <input type="text" name="firstName"
                                                    value={userForm.firstName || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="firstName" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input type="text" name="lastName"
                                                    value={userForm.lastName || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="lastName" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="email">Email</label>
                                                <input type="text" name="email"
                                                    value={userForm.email || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="email" />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="mobile">Mobile</label>
                                                <div className="clearfix"></div>
                                                <IntlTelInput
                                                    value={userForm.mobile}
                                                    containerClassName="intl-tel-input"
                                                    inputClassName="form-control number"
                                                    preferredCountries={['in']}
                                                    onSelectFlag={(num, country) => {
                                                        console.log('onSelectFlag', country);
                                                    }}
                                                    onPhoneNumberChange={this.onChangeMobile}
                                                />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="password">Password</label>
                                                <input type="text" name="password"
                                                    value={userForm.password || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="password" />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="designation">Designation</label>
                                                <input type="text" name="designation"
                                                    value={userForm.designation || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="designation" />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="avatar">Profile Image</label>
                                                <input type="file" name="avatar"
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="avatar" />
                                            </div>
                                        </div>
                                        <h4 className="card-title">Company Information</h4>
                                        <hr />
                                        <div className="row">
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="companyName">Company Name</label>
                                                <input type="text" name="companyName"
                                                    value={userForm.companyName || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="companyName" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="companyLogo">Company Logo</label>
                                                <input type="file" name="companyLogo"
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="companyLogo" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="companyDocument">Company Document</label>
                                                <input type="file" name="companyDocument"
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="companyDocument" />
                                            </div>
                                            <div className="form-group col-sm-8">
                                                <label htmlFor="postalAddress">Address</label>
                                                <input type="text" name="postalAddress"
                                                    value={userForm.postalAddress || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="postalAddress" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="state">State</label>
                                                <input type="text" name="state"
                                                    value={userForm.state || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="state" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="city">City</label>
                                                <input type="text" name="city"
                                                    value={userForm.city || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="city" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="postcode">Postcode</label>
                                                <input type="text" name="postcode"
                                                    value={userForm.postcode || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="postcode" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="country">Country</label>
                                                <input type="text" name="country"
                                                    value={userForm.country || ''}
                                                    onChange={this.handleChange.bind(this)}
                                                    className="form-control" id="country" />
                                            </div>
                                        </div>
                                        <h4 className="card-title">Categories Information</h4>
                                        <hr />
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="categories">Categories</label>
                                                <Select
                                                    value={businessTypes.filter(obj => userForm.categories.includes(obj.value))}
                                                    onChange={this.handleChange.bind(this)}
                                                    options={businessTypes}
                                                    isMulti
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                        <h4 className="card-title">Brand Information
                                            <button className="btn btn-gradient-success btn-sm" onClick={this.addMoreRow.bind(this)}> <i className="mdi mdi-plus-box"></i> Add new row</button>
                                        </h4>
                                        <hr />
                                        {
                                            userForm.brands && userForm.brands.map((br, index) => {
                                                return (<div className="row" key={index}>
                                                    <div className="form-group col-sm-5">
                                                        <label htmlFor="brand1">Brand</label>
                                                        <Select
                                                            name="brand"
                                                            value={brandsArr.filter(obj => userForm.brands[index]['brand'] == (obj.value))}
                                                            onChange={this.formArrayChange.bind(this, index, 'brand')}
                                                            options={brandsArr}
                                                            isClearable
                                                        />
                                                        {/* <input className="form-control"
                                                            name="brand"
                                                            onChange={this.formArrayChange.bind(this, index)}
                                                            type="text" /> */}
                                                    </div>
                                                    <div className="form-group col-sm-5">
                                                        <label htmlFor="brand1">Relationship</label>
                                                        <input className="form-control"
                                                            name="relationship"
                                                            onChange={this.formArrayChange.bind(this, index, 'relationship')}
                                                            type="text" />
                                                    </div>
                                                </div>
                                                )
                                            })
                                        }

                                    </div>
                                    <button type="button" onClick={this.handleSubmit} className="btn btn-gradient-info btn-fw">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SellerComponent;