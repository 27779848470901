import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from '../common/Loader';
import { TextEditor } from '../element/TextEditor';

const formObj = {
    question: "",
    answer: ""
}
function FaqsComponent() {
    const history = useHistory();
    const [inputField, setInputField] = useState(formObj);
    const [isLoading, setIsLoading] = useState(false);
    const [answerField, setAnswerField] = useState("");

    useEffect(() => {
        getFAQsDetails();
    }, [])
    let { faqsId } = useParams();

    const getFAQsDetails = async () => {
        if (faqsId) {
            await UserServices.detailFAQs(faqsId).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode === 200) {
                    setAnswerField(data.answer);
                    setInputField({ ...inputField, ...data });
                }
                else {
                    toast.error(message);
                }
            })
        }
    };
    const inputEditorHandler = (e, evalue, isEditor) => {
        setAnswerField(evalue)
    }
    const inputHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }
    const submitForm = async (e) => {
        e.preventDefault();
        inputField.answer = answerField;
        if (inputField.question != "" && inputField.answer != "") {
            setIsLoading(true);
            if (faqsId) {
                inputField.faqId = faqsId;
                UserServices.updateFAQs(inputField).then((responce) => {
                    let { data, message, statusCode } = responce.data;
                    if (statusCode == 200) {
                        toast.success(message);
                        if (history) history.push('/faqs');
                    }
                    else {
                        toast.error(message);
                    }
                    setIsLoading(false);
                })
            }
            else {
                UserServices.addFAQs(inputField).then((responce) => {
                    let { data, message, statusCode } = responce.data;
                    if (statusCode == 200) {
                        toast.success(message);
                        if (history) history.push('/faqs');
                    }
                    else {
                        toast.error(message);
                    }
                    setIsLoading(false);
                })
            }
        }
        else {
            toast.error('Please fill all the required fields')
        }
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Mange FAQs
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card card-box">
                            <div className="card-body card-body-pd">
                                <form encType="multipart/form-data">
                                    <div className="form-horizontal">
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="question" className="required">Question</label>
                                                <input type="text" name="question"
                                                    value={inputField.question}
                                                    onChange={inputHandler}
                                                    className="form-control" id="question" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="answer" className="required">Answer</label>
                                                <TextEditor
                                                    initialContent={answerField}
                                                    name='answer'
                                                    onChange={inputEditorHandler}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" onClick={submitForm} className="btn btn-gradient-info btn-fw">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqsComponent;