import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Select from 'react-select';
import { UserServices } from "../../services";
import { CATEGORY_TYPES } from "../../config/constant";
import Loader from '../common/Loader';
import BreadCrumb from '../common/BreadCrumb';
import { TEXT, displayDate } from "../../utils";
function ReturnedProductList() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    useEffect(() => {
        getProductList();
    }, [])
    let { categoryId } = useParams();
    const getProductList = async () => {
        await UserServices.productAdminList({}).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                console.log(data);
                setProducts(data.list);
            }
            else {
                toast.error(message);
            }
        })
    };
    const changeStatus = (evnt, brandId) => { }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <BreadCrumb title={"Return product list by users"} />
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>User</th>
                                                <th>Product</th>
                                                <th>Original Price</th>
                                                <th>Selling Price</th>
                                                <th>Stock</th>
                                                <th>Total Selling</th>
                                                <th>Seller</th>
                                                <th>Resion</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products && products.map((btype, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>
                                                            <div className="td-grid-profile">
                                                                <img src="" class="mr-2" />
                                                                <div class="nav-profile-text d-flex flex-column">
                                                                    <a class="cursor-pointer font-weight-bold mb-1 mt-1">
                                                                        CK
                                                                    </a>
                                                                    <span class="text-secondary text-small">kk</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="td-grid-profile">
                                                                <img src="" class="mr-2" />
                                                                <div class="nav-profile-text d-flex flex-column">
                                                                    <a class="cursor-pointer font-weight-bold mb-1 mt-1">
                                                                        {TEXT(btype.title, 15)}
                                                                    </a>
                                                                    <span class="text-secondary text-small">{btype.uniqueId}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{btype.currency} {btype.originalPrice}</td>
                                                        <td>{btype.currency} {btype.sellingPrice}</td>
                                                        <td>{btype.totalUnits}</td>
                                                        <td>{btype.soldUnits}</td>
                                                        <td>{btype.vender ? (btype.vender.firstName + ' ' + btype.vender.lastName) : ""}</td>
                                                        <td>{btype.vender ? (btype.vender.firstName + ' ' + btype.vender.lastName) : ""}</td>
                                                        <td>
                                                            {btype.status == true ?
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id)} className="switch-input" checked={btype.status || false} />
                                                                    <span className="switch-slider"></span>
                                                                </label> :
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id)} checked={btype.status || false} className="switch-input" />
                                                                    <span className="switch-slider"></span>
                                                                </label>
                                                            }
                                                        </td>
                                                        <td>
                                                            <Link to={`/product/${btype._id}`}>Edit</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ReturnedProductList;