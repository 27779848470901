import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Select from 'react-select';
import { NotificationService, UserServices } from "../../services";
import { CATEGORY_TYPES } from "../../config/constant";
import Loader from '../common/Loader';
import BreadCrumb from '../common/BreadCrumb';
import filePATH from "../../webroot/sample-file/productexcel.xls";
import UploadFile from '../element/UploadFile';
function ServiceList() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [exportModal, setExportModal] = useState(false);
    useEffect(() => {
        getProductList();
    }, [])
    let { categoryId } = useParams();
    const getProductList = async () => {
        await UserServices.serviceAdminList({}).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                console.log(data);
                setProducts(data.list);
            }
            else {
                toast.error(message);
            }
        })
    };
    const changeStatus = (evnt, serviceId, i) => {
        let obj = {
            serviceId: serviceId,
            status: evnt.target.checked
        }
        UserServices.serviceUpdateStatus(obj).then((responce) => {
            let { message, statusCode } = responce.data;
            if (statusCode === 200) {
                toast.success(message)
                products[i]['status'] = obj.status;
                setProducts([...products]);
            }
            else {
                toast.error(message);
            }
        })
    }
    const openModal = () => {
        setExportModal(true);
        document.body.classList.add('modal-open');
    }
    const closeModal = () => {
        setExportModal(false);
        document.body.classList.remove('modal-open')
    }
    const onSuccess = () => {
        closeModal();
        getProductList();
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <BreadCrumb title={"Service List"} />
                <Loader isLoading={isLoading} />
                <div className="row">
                    {/* <div className="col-lg-12 grid-margin-sm">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="float-right">
                                    <a class="btn btn-gradient-dark btn-sm btn-icon-text m-r-10" href={filePATH} download><i class="mdi mdi-cloud-download"></i> Download Sample File</a>
                                    <button onClick={() => openModal()} type="button" class="btn btn-gradient-primary btn-sm btn-icon-text m-r-10">
                                        <i class="mdi mdi-upload btn-icon-prepend"></i> Upload Excel
                                    </button>
                                    <UploadFile show={exportModal} filePATH={filePATH} onSuccess={onSuccess} from={"product"} closeModal={closeModal} title={'Import Product Excel'} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th className="th-5">S.No.</th>
                                                <th className="th-20">Name</th>
                                                <th className="th-10">Category</th>
                                                <th className="th-10">Sub Category</th>
                                                <th className="th-10">Original Price</th>
                                                <th className="th-10">Selling Price</th>
                                                <th className="th-10">Estimated Time</th>
                                                <th className="th-10">Seller</th>
                                                <th className="th-5">Status</th>
                                                <th className="th-10">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products && products.map((btype, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td><img src={btype.thumbnail} class="mr-2" />{btype.title}</td>
                                                        <td>{btype.category ? btype.category.title : ""}</td>
                                                        <td>{btype.subcategory ? btype.subcategory.title : ""}</td>
                                                        <td>{btype.basic ? btype.basic.currency + ' ' + btype.basic.originalPrice : ""}</td>
                                                        <td>{btype.basic ? btype.basic.currency + ' ' + btype.basic.sellingPrice : ""}</td>
                                                        <td>{btype.basic ? btype.basic.estimate + ' ' + btype.basic.estimateType : ""}</td>
                                                        <td><img src={btype.vendor ? btype.vendor.avatar : ""} class="mr-2" alt="image" />{btype.vendor ? (btype.vendor.firstName + ' ' + btype.vendor.lastName) : ""}</td>
                                                        <td>
                                                            {btype.status == true ?
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id, idx)} className="switch-input" checked={btype.status || false} />
                                                                    <span className="switch-slider"></span>
                                                                </label> :
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id, idx)} checked={btype.status || false} className="switch-input" />
                                                                    <span className="switch-slider"></span>
                                                                </label>
                                                            }
                                                        </td>
                                                        <td>
                                                            <Link className="btn btn-gradient-info btn-sm" to={`/service/${btype._id}`}><i className="mdi mdi-pencil"></i></Link>&nbsp;
                                                            {/* <Link className="btn btn-gradient-primary btn-sm" to={`/product/detail/${btype._id}`}><i className="mdi mdi-eye"></i></Link> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceList;