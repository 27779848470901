import ActionTypes from "./action"
export const userDetail = (data) => {
    return {
        type: ActionTypes.LOGIN_USER,
        payload: data
    }
}
export const addUserDetail = (data) => {
    return {
        type: ActionTypes.ADD_LOGIN_USER,
        payload: data
    }
}