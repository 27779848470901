import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Loader from '../common/Loader';
import { UserServices } from "../../services";
import { CONTENT_TYPES } from "../../config/constant"
import { TextEditor } from '../element/TextEditor';
const formObj = {
    type: "privacy-policy",
    title: "",
    description: "",
    banner: "",
    image: "",
    urlKey: "",
    metaTitle: "",
    metaKewords: "",
    metaDescription: ""
}
const editorConfiguration = {
};
function ContentComponent() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [inputField, setInputField] = useState(formObj);
    const [textAreaField, setTextAreaField] = useState("");
    const [banners, setBanners] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    let { contentId } = useParams();
    useEffect(() => {
        getContentDetail();
    }, []);
    const getContentDetail = async () => {
        if (contentId) {
            setIsLoading(true);
            await UserServices.contentDetail({ contentId }).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode === 200) {
                    let formObj = {
                        type: data.type,
                        title: data.title,
                        urlKey: data.urlKey,
                        metaTitle: data.metaTitle,
                        metaKewords: data.metaKewords,
                        metaDescription: data.metaDescription
                    }
                    setInputField({ ...inputField, ...formObj });
                    setTextAreaField(data.description)
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
    }
    const inputEditorHandler = (e, evalue, isEditor) => {
        setTextAreaField(evalue)
    }
    const inputHandler = (e) => {
        if (e.target.files) {
            setSelectedFile(e.target.files[0]);
            setIsFilePicked(true);
        }
        else {
            setInputField({ ...inputField, [e.target.name]: e.target.value });
        }
    }
    const getAllBanners = async (displayAt) => {
        UserServices.dropdownList({ displayAt: displayAt }).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setBanners(data);
            }
            else {
                toast.error(message);
            }
        })
    }
    const submitForm = async (e) => {
        e.preventDefault();
        if (inputField.type != "" && inputField.title != "") {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('title', inputField.title);
            formData.append('description', textAreaField);
            formData.append('type', inputField.type);
            formData.append('urlKey', inputField.urlKey);
            formData.append('metaTitle', inputField.metaTitle);
            formData.append('metaKewords', inputField.metaKewords);
            formData.append('metaDescription', inputField.metaDescription);
            formData.append('image', selectedFile);
            if (contentId) {
                formData.append('contentId', contentId);
                UserServices.updateContent(formData).then((responce) => {
                    let { data, message, statusCode } = responce.data;
                    if (statusCode == 200) {
                        toast.success(message);
                        if (history) history.push('/content');
                    }
                    else {
                        toast.error(message);
                    }
                    setIsLoading(false);
                })
            }
            else {
                UserServices.addContent(formData).then((responce) => {
                    let { data, message, statusCode } = responce.data;
                    if (statusCode == 200) {
                        toast.success(message);
                        if (history) history.push('/content');
                    }
                    else {
                        toast.error(message);
                    }
                    setIsLoading(false);
                })
            }
        }
        else {
            toast.error('Please fill all the required field')
        }
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Mange Content
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card card-box">
                            <div className="card-body card-body-pd">
                                <form encType="multipart/form-data">
                                    <div className="form-horizontal">
                                        <div className="row">
                                            <div className="form-group col-sm-2">
                                                <label htmlFor="bannerFor" className="required">Content For</label>
                                                <select disabled={contentId ? true : false} className="form-control" id="contentFor"
                                                    name="type"
                                                    value={inputField.type}
                                                    onChange={inputHandler}>
                                                    {CONTENT_TYPES && CONTENT_TYPES.map((btype, indx) => {
                                                        return <option key={indx} value={btype.key}>{btype.label}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label htmlFor="image">Banner Images</label>
                                                <input type="file" name="image"
                                                    onChange={inputHandler}
                                                    className="form-control" id="image" required />
                                            </div>
                                            <div className="form-group col-sm-8">
                                                <label htmlFor="title" className="required">Title</label>
                                                <input type="text" name="title"
                                                    value={inputField.title}
                                                    onChange={inputHandler}
                                                    className="form-control" id="title" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="description">Description</label>
                                                <TextEditor
                                                    initialContent={textAreaField}
                                                    name='description'
                                                    onChange={inputEditorHandler}
                                                />
                                            </div>
                                        </div>
                                        <h4>Search Engine Optimization</h4>
                                        <hr />
                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="urlKey">URL KEY</label>
                                                <input type="text" name="urlKey"
                                                    value={inputField.urlKey}
                                                    onChange={inputHandler}
                                                    className="form-control" id="urlKey" />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="metaTitle">META TITLE</label>
                                                <input type="text" name="metaTitle"
                                                    value={inputField.metaTitle}
                                                    onChange={inputHandler}
                                                    className="form-control" id="metaTitle" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="metaKewords">META KEYWORDS</label>
                                                <textarea name="metaKewords"
                                                    value={inputField.metaKewords}
                                                    onChange={inputHandler}
                                                    className="form-control" id="metaKewords" rows="4"></textarea>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="metaDescription">META DESCRIPTION</label>
                                                <textarea name="metaDescription"
                                                    value={inputField.metaDescription}
                                                    onChange={inputHandler}
                                                    className="form-control" id="metaDescription" rows="4"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" onClick={submitForm} className="btn btn-gradient-info btn-fw">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentComponent;