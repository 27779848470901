const RowLoader = () => {
    return (
        <div id="dots2">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}
export default RowLoader;