import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import Chart from "react-apexcharts";
import { UserServices } from "../../services";
const chartData = {
    options: {
        chart: {
            id: 'apexchart-example'
        },
        xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
    },
    series: [{
        name: 'series-1',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
    }],
    animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    }
};
const donut = {
    options: {},
    series: [44, 1, 10, 5, 2,5],
    labels: ['ordered', 'shipped', 'outForDelivery', 'delivered', 'return', 'cancel'],
    animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    }
}
function AnalyticsComponent() {
    const [dashboard, setDashboard] = useState({
        user: 0,
        seller: 0,
        stock: 0,
        sales: 0,
        orders: 0,
    });
    useEffect(() => {
        getDashboard();
    }, [])
    const getDashboard = () => {
        UserServices.dashboard().then((responce) => {
            let { data, message } = responce.data;
            setDashboard(data);
        })
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Analytics
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-xl-9 col-sm-12">
                        <div className="row">
                            <div className="col-sm-4 grid-margin">
                                <div class="card bg-gradient-danger text-white">
                                    <div class="card-body">
                                        <div class="grey-circle-profile-icon">
                                            <i class="mdi mdi-account-outline"></i>
                                        </div>
                                        <h2 class="mb-0 mt-3">183,954</h2>
                                        <h5 class="font-weight-normal mb-3">Customers</h5>
                                        <p class="text-medium m-0">Decreased by 10%</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 grid-margin">
                                <div class="card bg-gradient-success text-white">
                                    <div class="card-body">
                                        <div class="grey-circle-profile-icon">
                                            <i class="mdi mdi-tshirt-crew-outline"></i>
                                        </div>
                                        <h2 class="mb-0 mt-3">$4,986</h2>
                                        <h5 class="font-weight-normal mb-3">Total orders</h5>
                                        <p class="text-medium m-0">Decreased by 10%</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 grid-margin">
                                <div class="card bg-gradient-primary text-white">
                                    <div class="card-body">
                                        <div class="grey-circle-profile-icon">
                                            <i class="mdi mdi-flask"></i>
                                        </div>
                                        <h2 class="mb-0 mt-3">$35,465</h2>
                                        <h5 class="font-weight-normal mb-3">Total sales</h5>
                                        <p class="text-medium m-0">Increased by 5%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6 col-sm-6">
                                <div className="card grid-margin-sm">
                                    <Chart
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="bar"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="card grid-margin-sm">
                                    <Chart
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="bar"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6 col-sm-6">
                                <div className="card grid-margin-sm">
                                    <Chart
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="bar"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="card grid-margin-sm">
                                    <Chart
                                        options={donut.options}
                                        series={donut.series}
                                        type="donut"
                                        height={301}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-12">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card grid-margin-sm">
                                    <div class="card-body px-3 py-4">
                                        <h5 class="mb-3">New order invoice creation.</h5>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="badge badge-gradient-success badge-pill">Somun Ae-Ri</div>
                                            <img src="../../assets/images/faces/face1.jpg" alt="profile" class="img-es rounded-circle" />
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="5" style={{ width: "50%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="text-muted mt-1">
                                            <p class="m-0">1 day 05 mins</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card grid-margin-sm">
                                    <div class="card-body px-3 py-4">
                                        <h5 class="mb-3">New business meeting</h5>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="badge badge-gradient-primary badge-pill">Anthony Gibson</div>
                                            <img src="../../assets/images/faces/face2.jpg" alt="profile" class="img-es rounded-circle" />
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="5" style={{ width: "50%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="text-muted mt-1">
                                            <p class="m-0">3 day 12 mins</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card grid-margin-sm">
                                    <div class="card-body px-3 py-4">
                                        <h5 class="mb-3">Media Outreach</h5>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="badge badge-gradient-warning badge-pill">Marcus Waters</div>
                                            <img src="../../assets/images/faces/face3.jpg" alt="profile" class="img-es rounded-circle" />
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="5" style={{ width: "50%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="text-muted mt-1">
                                            <p class="m-0">2 day 12mins</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card grid-margin-sm">
                                    <div class="card-body px-3 py-4">
                                        <h5 class="mb-3">Website Maintenance</h5>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="badge badge-gradient-info badge-pill">Rachel Gross</div>
                                            <img src="../../assets/images/faces/face4.jpg" alt="profile" class="img-es rounded-circle" />
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="5" style={{ width: "50%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="text-muted mt-1">
                                            <p class="m-0">1 day 20mins</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card grid-margin-sm">
                                    <div class="card-body px-3 py-4">
                                        <h5 class="mb-3">Content Development</h5>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="badge badge-gradient-danger badge-pill">Derrick Chapman</div>
                                            <img src="../../assets/images/faces/face5.jpg" alt="profile" class="img-es rounded-circle" />
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="5" style={{ width: "50%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="text-muted mt-1">
                                            <p class="m-0">2 day 30mins</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card grid-margin-sm">
                                    <div class="card-body px-3 py-4">
                                        <h5 class="mb-3">Customer Service</h5>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="badge badge-gradient-success badge-pill">Caroline Ingram</div>
                                            <img src="../../assets/images/faces/face6.jpg" alt="profile" class="img-es rounded-circle" />
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="5" style={{ width: "50%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="text-muted mt-1">
                                            <p class="m-0">3 day 40mins</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card grid-margin-sm">
                                    <div class="card-body px-3 py-4">
                                        <h5 class="mb-3">Social Media Management</h5>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="badge badge-gradient-dark badge-pill">Derrick Chapman</div>
                                            <img src="../../assets/images/faces/face7.jpg" alt="profile" class="img-es rounded-circle" />
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="5" style={{ width: "50%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="text-muted mt-1">
                                            <p class="m-0">3 day 37 mins</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card grid-margin-sm">
                                    <div class="card-body px-3 py-4">
                                        <h5 class="mb-3">Logistics.</h5>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="badge badge-gradient-warning badge-pill">Gene Clayton</div>
                                            <img src="../../assets/images/faces/face7.jpg" alt="profile" class="img-es rounded-circle" />
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="5" style={{ width: "50%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="text-muted mt-1">
                                            <p class="m-0">2 day 50 mins</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-body px-3 py-4">
                                        <h5 class="mb-3">Market Research</h5>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="badge badge-gradient-danger badge-pill">Myrtle Floyd</div>
                                            <img src="../../assets/images/faces/face7.jpg" alt="profile" class="img-es rounded-circle" />
                                        </div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="5" style={{ width: "50%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="text-muted mt-1">
                                            <p class="m-0">1 day 42 mins</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnalyticsComponent;