import Routes from "./routes/router";
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      <Routes />
    </>
  );
}

export default App;
