import { useEffect, useState } from "react";
import Select from 'react-select';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from "../common/Loader";
import { Calendra } from "../element/CalendarPicker";
import { useParams, useHistory } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { ESTIMATED_TIME_TYPES } from "../../config/constant";
let formArr = {
    key: "", label: "", value: ""
};
const prodObj = {
    title: "",
    description: "",
    category: "",
    subcategory: "",
    images: [],
    thumbnail: "",
    basic: {
        originalPrice: "",
        sellingPrice: "",
        currency: "",
        chargesType: "",
        estimate: "",
        estimateType: "hourly"
    }
}
const setProductImagesArr = [
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" }
]
function ManageService({ userId, userData }) {
    const [productForm, setProductForm] = useState(prodObj);
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [subcategoriesArr, setSubcategoriesArr] = useState([]);

    const [productImages, setProductImages] = useState(setProductImagesArr);
    const [isLoading, setIsLoading] = useState(false);
    const [actionType, setActionType] = useState("Add");
    const { productId } = useParams();
    const history = useHistory();
    // console.log(productId);
    useEffect(() => {
        getAllCategories();
        if (productId !== 'add') {
            getProductDetail();
            setActionType("Update")
        }
    }, [])
    const getProductDetail = () => {
        UserServices.editService({ serviceId: productId }).then((responce) => {
            let { data, message, status } = responce.data;
            if (status == 200) {
                setSubcategoriesArr(data.subcategories);
                delete data.subcategories;
                setProductForm(data);
                data && data.images.map((m, i) => {
                    setProductImagesArr[i] = { 'file': '', 'img': m }
                })
                setProductImages(setProductImagesArr);
            }
            else {
                toast.error(message);
            }
        })
    }
    const getAllCategories = () => {
        UserServices.categoriesDropDownList().then((responce) => {
            let { data, message, status } = responce.data;
            if (status == 200) {
                setCategoriesArr(data.list);
            }
        })
    }
    const changeHandler = (e, name = "") => {
        name = name ? name : e.target.name;
        switch (name) {
            case 'category':
                setProductForm((prev) => ({
                    ...prev,
                    category: e ? e.value : ""
                }));
                if (e) setSubcategoriesArr(e.subcategories)
                break;
            case 'subcategory':
                setProductForm((prev) => ({
                    ...prev,
                    [name]: e ? e.value : ""
                }))
                break;
            case 'originalPrice':
            case 'sellingPrice':
            case 'estimate':
            case 'estimateType':
                setProductForm((prev) => ({
                    ...prev,
                    basic: {
                        ...prev.basic,
                        [e.target.name]: e.target.value
                    }
                }))
                break;
            case 'chargesType':
                setProductForm((prev) => ({
                    ...prev,
                    basic: {
                        ...prev.basic,
                        'chargesType': e ? e.value : ""
                    }
                }))
                break;
            default:
                setProductForm((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value
                }))
                break;
        }
    }
    const submitProductForm = (e) => {
        setIsLoading(true);
        if (productId) {
            delete productForm.vender;
            productForm.serviceId = productId;
            UserServices.updateService(productForm).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode == 200) {
                    toast.success(message);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
        else {
            productForm.vender = userId;
            UserServices.addService(productForm).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode == 200) {
                    toast.success(message);
                    if (history) history.push('/my/update-service/' + data._id);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
    }
    const removeFile = (i) => {
        productImages[i] = {
            img: "",
            file: ""
        }
        setProductImages([...productImages]);
    }
    const changeFile = (e, i, obj) => {
        if (obj.img != '' && obj.file == '') {
            let imgu = obj.img.split('/').pop();
            // setRemovedImages((prev) => ([...prev, imgu]));
        }
        productImages[i] = {
            img: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
        }
        setProductImages([...productImages]);
    }
    const submitProductImage = (e) => {
        const formData = new FormData();
        productImages.filter(obj => {
            if (obj.file != "") {
                formData.append('images', obj.file);
                return 1;
            }
        })
        formData.append('productId', productId);
        UserServices.updateServiceImages(formData).then((responce) => {
            let { message, statusCode } = responce;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span>Manage Service
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card card-box">
                            <div className="card-body card-body-pd">
                                <div className="d_boxs  mb-2 mb-md-4">
                                    <ul className="nav custome-nav mb-2 mb-md-3">
                                        {/* <li className="nav-item">
                                            <a className=" active" data-toggle="tab" href="#product">Product</a>
                                        </li> */}
                                        {/* <li className="nav-item">
                                            <a className="" data-toggle="tab" href="#productImage">Product Images </a>
                                        </li> */}
                                        {/* <li className="nav-item">
                                            <a className="" data-toggle="tab" href="#uploadProducts">Upload Product</a>
                                        </li> */}
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active " id="product">
                                            <div className="row">
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="required"> Category</label>
                                                    <Select
                                                        onChange={(e) => changeHandler(e, 'category')} name="category"
                                                        value={categoriesArr.filter(obj => productForm.category == (obj.value))}
                                                        options={categoriesArr}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="required">Sub Category</label>
                                                    <Select
                                                        onChange={(e) => changeHandler(e, 'subcategory')} name="subcategory"
                                                        value={subcategoriesArr.filter(obj => productForm.subcategory == (obj.value))}
                                                        options={subcategoriesArr}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-6 mb-md-6">
                                                    <label className="">Service Name</label>
                                                    <input type="text" onChange={changeHandler} name="title" value={productForm.title} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group mb-md-4">
                                                <label className="">Condition</label>
                                                <TextareaAutosize minRows={3.5} className="form-control" onChange={changeHandler} name="conditions" value={productForm.conditions} />
                                            </div>
                                            <div className="form-group mb-md-4">
                                                <label className="">Descriptions</label>
                                                <TextareaAutosize minRows={3.5} className="form-control" onChange={changeHandler} name="description" value={productForm.description} />
                                            </div>
                                            <h6 className="color_man">Price Informations</h6>
                                            <div className="row">
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="required">Charges Type</label>
                                                    <Select
                                                        onChange={(e) => changeHandler(e, 'chargesType')} name="chargesType"
                                                        value={ESTIMATED_TIME_TYPES.filter(obj => productForm.basic.chargesType == (obj.value))}
                                                        options={ESTIMATED_TIME_TYPES}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Listed (Original) Price </label>
                                                    <div className="position_relative_inr">
                                                        <span className="inr_a">{productForm && productForm.basic ? productForm.basic.currency : ""}</span>
                                                        <input type="text" onChange={changeHandler} name="originalPrice" value={productForm.basic.originalPrice} className="form-control decimal" />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="">Promotional (Selling) Price </label>
                                                    <div className="position_relative_inr">
                                                        <span className="inr_a">{productForm && productForm.basic ? productForm.basic.currency : ""}</span>
                                                        <input type="text" onChange={changeHandler} name="sellingPrice" value={productForm.basic.sellingPrice} className="form-control number" />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <label className="required">Service Estimate Time</label>
                                                    <div className="form-input-select">
                                                        <input type="text" onChange={changeHandler} name="estimate" value={productForm.basic.estimate} className="form-control decimal" />
                                                        <select className="form-control" onChange={changeHandler} name="estimateType">
                                                            {ESTIMATED_TIME_TYPES && ESTIMATED_TIME_TYPES.map((obj) => {
                                                                return (<option selected={obj.value == productForm.basic.estimateType ? 'selected' : null} key={obj.value} value={obj.value}>{obj.label}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                    <button type="button" onClick={submitProductForm} className="btn btn-primary pr-4 pl-4">SUBMIT</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="uploadProducts">
                                            {/* <a className="" href={productSample} download><i className="fa fa-download"></i> Download Sample File</a>
                        <div className="row">
                            <div className="form-group col-lg-4 col-md-6 col-sm-6 mb-md-4">
                                <input type="file" className="form-control" />
                            </div>
                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ManageService;