import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Select from 'react-select';
import { UserServices } from "../../services";
import { CATEGORY_TYPES } from "../../config/constant";
import Loader from '../common/Loader';
import BreadCrumb from '../common/BreadCrumb';
import { Shimmer, Image } from 'react-shimmer'
import UserSellerProfile from '../user/UserSellerProfile';

function ProductDetils() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [productData, setProductData] = useState({});
    const [userData, setUserData] = useState({});
    const { productId } = useParams();
    useEffect(() => {
        getProductDetails();
    }, [])
    const getProductDetails = async () => {
        await UserServices.productDetail(productId).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setProductData(data.product);
                setUserData(data.user);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    };
    const changeStatus = (evnt, brandId) => { }
    const ratePercent = (rate, totalRate) => {
        return totalRate < 1 ? 0 : (rate * 100 / totalRate);
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <BreadCrumb title={"Product Details"} />
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-box">
                            <div className="card-header">
                                <ul className="nav custome-nav">
                                    <li className="nav-item">
                                        <a className=" active" data-toggle="tab" href="#product">Product Information</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="" data-toggle="tab" href="#productImage">Product Images </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="" data-toggle="tab" href="#productSpecification">Product Specification</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="" data-toggle="tab" href="#aboutSeller">Product Seller Profile</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body card-body-pd">
                                <div className="tab-content">
                                    <div className="tab-pane active " id="product">
                                        <div className="row">
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Model Number</label>
                                                <span className="form-control">{productData.modelNumber || ""}</span>
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Part Number/ Product ID </label>
                                                <span className="form-control">{productData.partNumber || ""}</span>
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Product Name</label>
                                                <span className="form-control">{productData.title || ""}</span>
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Manufacturer</label>
                                                <span className="form-control">{productData.manufacturData || ""}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Brand</label>
                                                <span className="form-control">{productData.brandData || ""}</span>
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Category</label>
                                                <span className="form-control">{productData.categoryData || ""}</span>
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Item Quantity</label>
                                                <span className="form-control">{productData.totalUnits || ""}</span>
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Sold Units</label>
                                                <span className="form-control">{productData.soldUnits || 0}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Release Date</label>
                                                <span className="form-control">{productData.releaseDate || ""}</span>
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Expiry Date</label>
                                                <span className="form-control">{productData.expiryDate || ""}</span>
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Listed (Original) Price</label>
                                                <span className="form-control">{productData.originalPrice || ""}</span>
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Promotional (Selling) Price</label>
                                                <span className="form-control">{productData.sellingPrice || ""}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label className="">Countries</label>
                                                <span className="form-control">
                                                    {productData.countries ? productData.countries.join(", ") : ""}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label className="">Condition</label>
                                                <span className="form-control">{productData.conditions || ""}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label className="">Descriptions</label>
                                                <span className="form-control">{productData.description || ""}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane " id="productImage">
                                        <div className="img-card img-view-container">
                                            {(productData && productData.images) && productData.images.map((imgObj, i) => {
                                                return (
                                                    <div key={i} className="img-card-body img-view-content">
                                                        <Image src={imgObj} fallback={<Shimmer width={'100%'} height={200} />} />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="productSpecification"></div>
                                    <div className="tab-pane" id="aboutSeller">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <UserSellerProfile userProf={userData} />
                                            </div>
                                            <div className="col-lg-8">
                                                <h4>Ratings & Reviews</h4>
                                                <div className="hm-contents">
                                                    <div className="rating-customer">
                                                        <div className="br-widget font-size-25">
                                                            {userData.averageRating ? userData.averageRating : 0}
                                                            <a className="br-selected pointer-none"></a>
                                                        </div>
                                                        {userData.totalRating ? userData.totalRating : 0} Ratings
                                                    </div>
                                                    <div>
                                                        <div className="br-widget font-size-25">
                                                            {userData.ratingData && Object.keys(userData.ratingData).map(rate => {
                                                                return (
                                                                    <div key={rate} className="rating-bar">{rate}
                                                                        <a className="br-selected pointer-none"></a>
                                                                        <div className="progress">
                                                                            <div className="progress-bar bg-gradient-success"
                                                                                role="progressbar"
                                                                                style={{ width: ratePercent(userData.ratingData[rate], userData.totalRating) + '%' }}
                                                                                aria-valuenow={ratePercent(userData.ratingData[rate], userData.totalRating)}
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100">
                                                                            </div>
                                                                        </div>
                                                                        <span>{userData.ratingData[rate]}</span>
                                                                        <br />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductDetils;