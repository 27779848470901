import BreadCrumb from "../common/BreadCrumb";
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from '../common/Loader';

function OnlineTransaction() {
    let [isLoading, setIsLoading] = useState(true);
    let [transaction, setTransaction] = useState([]);
    const [page, setPage] = useState(1);
    let { type } = useParams();
    useEffect(() => {
        getAllTransactions({}, 1);
    }, [type])
    const getAllTransactions = (searchObj, pageNo = page) => {
        let obj = { ...searchObj, page: pageNo, type }
        UserServices.buyProductList(obj).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                setTransaction(data.list)
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <BreadCrumb title={'Buy ' + type} />
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>User</th>
                                                <th>Product</th>
                                                <th>Quantity</th>
                                                <th>Transaction Id</th>
                                                <th>Order Id</th>
                                                <th>Price</th>
                                                <th>Paid Amount</th>
                                                <th>Payment Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transaction && transaction.map((bp, indx) => {
                                                return (
                                                    <tr>
                                                        <td>{indx + 1}</td>
                                                        <td>
                                                            <div class="td-grid-profile">
                                                                <img src={bp.user ? bp.user.profileImage : ""} class="mr-2" />
                                                                <div class="nav-profile-text d-flex flex-column">
                                                                    <a class="cursor-pointer font-weight-bold mb-1 mt-1">{bp.user ? bp.user.firstName + ' ' + bp.user.lastName : ""}</a>
                                                                    <span class="text-secondary text-small">{bp.user ? bp.user.email : ""}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="td-grid-profile">
                                                                <img src={bp.product ? bp.product.thumbnailImage : ""} class="mr-2" />
                                                                <div class="nav-profile-text d-flex flex-column">
                                                                    <a class="cursor-pointer font-weight-bold mb-1 mt-1">{bp.product ? bp.product.title : ""}</a>
                                                                    <span class="text-secondary text-small">{bp.product ? bp.product.email : ""}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{bp.items}</td>
                                                        <td>{bp.buyproducts ? bp.buyproducts.transactionId : ""}</td>
                                                        <td>{bp.buyproducts ? bp.buyproducts.orderId : ""}</td>
                                                        <td>{bp.currency} {bp.sellingPrice}</td>
                                                        <td>{bp.currency} {bp.buyproducts ? bp.buyproducts.paidAmount : ""}</td>
                                                        <td>
                                                            {bp.buyproducts && bp.buyproducts.paymentStatus ?
                                                                <label class="badge badge-gradient-success">DONE</label> :
                                                                <label class="badge badge-gradient-danger">REJECTED</label>
                                                            }
                                                            <Link className="badge badge-gradient-primary" to={'/order-details/' + bp._id}><i className="mdi mdi-eye"></i> View</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OnlineTransaction;