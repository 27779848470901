import BannerComponent from "../view/banner/BannerComponent";
import BannerListComponent from "../view/banner/BannerListComponent";
import BrandListComponent from "../view/brands/BrandListComponent";
import ProductCategoryListComponent from "../view/product/ProductCategoryListComponent";
import ContentComponent from "../view/content/ContentComponent";
import ContentListController from "../view/content/ContentListController";
import HomeComponent from "../view/Home/HomeComponent";
import LoginComponent from "../view/Home/LoginComponent";
import SellerComponent from "../view/seller/SellerComponent";
import SellerListComponent from "../view/seller/SellerListComponent";
import SellerPofileComponent from "../view/seller/SellerPofileComponent";
import UserComponent from "../view/user/UserComponent";
import UserListComponent from "../view/user/UserListComponent";
import UserProfileComponent from "../view/user/UserProfileComponent";
import AnalyticsComponent from "../view/Home/AnalyticsComponent";
import FaqsListComponent from "../view/faqs/FaqsListComponent";
import FaqsComponent from "../view/faqs/FaqsComponent";
import BrandComponent from "../view/brands/BrandComponent";
import ProductCategoryComponent from "../view/product/ProductCategoryComponent";
import AdminProfile from "../view/admin/AdminProfile";
import ForgotPassword from "../view/Home/ForgotPassword";
import ConatctList from "../view/contact/ConatctList";
import ProductList from "../view/product/ProductList";
import ReviewRatings from "../view/review/ReviewRatings";
import ManageProduct from "../view/product/MangeProduct";
import ProductInCart from "../view/product/ProductInCart";
import ReturnedProductList from "../view/product/ReturnedProductList";
import NewsLetterSubscriber from "../view/user/NewsLetterSubscriber";
import BuyPackage from "../view/reports/BuyPackage";
import OnlineTransaction from "../view/reports/OnlineTransaction";
import PackageList from "../view/package/PackageList";
import PackageManagement from "../view/package/PackageManagement";
import ProductDetils from "../view/product/ProductDetils";
import ServiceList from "../view/service/ServiceList";
import ManageService from "../view/service/ManageService";
import PromotionBanner from "../view/banner/PromotionBanner";
import OrderDetails from "../view/reports/OrderDetails";
const routes = [
  {
    path: '/login',
    exact: true,
    auth: false,
    component: LoginComponent
  },
  {
    path: '/forgotpassword',
    exact: true,
    auth: false,
    component: ForgotPassword
  },
  {
    path: '/',
    exact: true,
    auth: true,
    component: HomeComponent
  },
  {
    path: '/home',
    exact: true,
    auth: true,
    component: HomeComponent
  },
  {
    path: '/analytics',
    exact: true,
    auth: true,
    component: AnalyticsComponent
  },
  {
    path: '/banner/add',
    exact: true,
    auth: true,
    component: BannerComponent
  },
  {
    path: '/banner/update/:bannerId',
    exact: true,
    auth: true,
    component: BannerComponent
  },
  {
    path: '/banner',
    exact: true,
    auth: true,
    component: BannerListComponent
  },
  {
    path: '/promotion-banner',
    exact: true,
    auth: true,
    component: PromotionBanner
  },
  {
    path: '/brand',
    exact: true,
    auth: true,
    component: BrandListComponent
  },
  {
    path: '/brand/add',
    exact: true,
    auth: true,
    component: BrandComponent
  },
  {
    path: '/brand/update/:brandId',
    exact: true,
    auth: true,
    component: BrandComponent
  },
  {
    path: '/manufactur',
    exact: true,
    auth: true,
    component: BrandListComponent
  },
  {
    path: '/manufactur/add',
    exact: true,
    auth: true,
    component: BrandComponent
  },
  {
    path: '/manufactur/update/:brandId',
    exact: true,
    auth: true,
    component: BrandComponent
  },
  {
    path: '/:type/category',
    exact: true,
    auth: true,
    component: ProductCategoryListComponent
  },
  {
    path: '/:type/category/add',
    exact: true,
    auth: true,
    component: ProductCategoryComponent
  },
  {
    path: '/:type/category/update/:categoryId',
    exact: true,
    auth: true,
    component: ProductCategoryComponent
  },
  {
    path: '/user/add',
    exact: true,
    auth: true,
    component: UserComponent
  },
  {
    path: '/user/:userId',
    exact: true,
    auth: true,
    component: UserComponent
  },
  {
    path: '/user/profile/:userId',
    exact: true,
    auth: true,
    component: UserProfileComponent
  },
  {
    path: '/user',
    exact: true,
    auth: true,
    component: UserListComponent
  },
  {
    path: '/seller',
    exact: true,
    auth: true,
    component: SellerListComponent
  },
  {
    path: '/seller/add',
    exact: true,
    auth: true,
    component: SellerComponent
  },
  {
    path: '/seller/:sellerId',
    exact: true,
    auth: true,
    component: SellerComponent
  },
  {
    path: '/seller/profile/:sellerId',
    exact: true,
    auth: true,
    component: SellerPofileComponent
  },
  {
    path: '/content',
    exact: true,
    auth: true,
    component: ContentListController
  },
  {
    path: '/content/add',
    exact: true,
    auth: true,
    component: ContentComponent
  },
  {
    path: '/content/update/:contentId',
    exact: true,
    auth: true,
    component: ContentComponent
  },
  {
    path: '/faqs',
    exact: true,
    auth: true,
    component: FaqsListComponent
  },
  {
    path: '/faqs/add',
    exact: true,
    auth: true,
    component: FaqsComponent
  },
  {
    path: '/faqs/update/:faqsId',
    exact: true,
    auth: true,
    component: FaqsComponent
  },
  {
    path: '/profile',
    exact: true,
    auth: true,
    component: AdminProfile
  },
  {
    path: '/contact',
    exact: true,
    auth: true,
    component: ConatctList
  },
  {
    path: '/subscribers',
    exact: true,
    auth: true,
    component: NewsLetterSubscriber
  },
  {
    path: '/products',
    exact: true,
    auth: true,
    component: ProductList
  },
  {
    path: '/products/in-cart',
    exact: true,
    auth: true,
    component: ProductInCart
  },
  {
    path: '/products/return',
    exact: true,
    auth: true,
    component: ReturnedProductList
  },
  {
    path: '/product/:productId',
    exact: true,
    auth: true,
    component: ManageProduct
  },
  {
    path: '/review/:pageId',
    exact: true,
    auth: true,
    component: ReviewRatings
  },
  {
    path: '/buy-packages',
    exact: true,
    auth: true,
    component: BuyPackage
  },
  {
    path: '/transactions/:type',
    exact: true,
    auth: true,
    component: OnlineTransaction
  },
  {
    path: '/package',
    exact: true,
    auth: true,
    component: PackageList
  },
  {
    path: '/package/add',
    exact: true,
    auth: true,
    component: PackageManagement
  },
  {
    path: '/package/update/:packageId',
    exact: true,
    auth: true,
    component: PackageManagement
  },
  {
    path: '/product/detail/:productId',
    exact: true,
    auth: true,
    component: ProductDetils
  },
  {
    path: '/service',
    exact: true,
    auth: true,
    component: ServiceList
  },
  {
    path: '/service/:productId',
    exact: true,
    auth: true,
    component: ManageService
  },
  {
    path: '/order-details/:productId',
    exact: true,
    auth: true,
    component: OrderDetails
  },
];
export default routes;