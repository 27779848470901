import { Component } from 'react';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DatePicker from "../element/DatePicker";
import Loader from '../common/Loader';
class UserComponent extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.state = {
            isLoading: false,
            userForm: {
                firstName: "",
                lastName: "",
                email: "",
                dob: "",
                mobile: "",
                postalAddress: "",
                country: "",
                state: "",
                city: "",
                postcode: ""
            }
        }
    }
    componentDidMount = async () => {
        await this.getDetail();
    }
    getDetail() {
        if (this.props.match.params.userId) {
            this.setState({ isLoading: true });
            let { userForm } = this.state;
            UserServices.userDetail(this.props.match.params.userId).then((responce) => {
                let { data, message, statusCode } = responce ? responce.data : {};
                if (statusCode === 200) {
                    let user = data.user;
                    userForm = {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        dialCode: user.dialCode,
                        iso2: user.iso2,
                        mobile: user.mobile,
                        postalAddress: user.address.postalAddress,
                        country: user.address.country,
                        state: user.address.state,
                        city: user.address.city,
                        postcode: user.address.postcode
                    }
                    this.setState({ userForm });
                }
                else {
                    toast.error(message);
                }
                this.setState({ isLoading: false });
            })
        }
    }
    handleChange(event, name = "") {
        let { userForm } = this.state;
        if (name === 'dob') {
            userForm[name] = event;
        }
        else {
            userForm[event.target.name] = event.target.value;
        }
        this.setState({ userForm });
    }
    onChangeMobile(status, value, countryData) {
        let { userForm } = this.state;
        userForm['mobile'] = value;
        userForm['dialCode'] = countryData.dialCode;
        userForm['iso2'] = countryData.iso2;
        this.setState({ userForm });
    }
    validForm() {
        let { userForm } = this.state;
        let isValid = false;
        if (userForm.mobile) {
            isValid = true;
        }
        if (userForm.email) {
            isValid = true;
        }
        return isValid;
    }
    handleSubmit(e) {
        let { userForm } = this.state;
        if (this.validForm()) {
            this.setState({ isLoading: true });
            if (this.props.match.params.userId) {
                userForm.userId = this.props.match.params.userId;
                UserServices.updateUser(userForm).then((responce) => {
                    let { message, statusCode } = responce.data;
                    if (statusCode === 200) {
                        toast.success(message);
                        const { history } = this.props;
                        if (history) history.push('/user');
                    }
                    else {
                        toast.error(message);
                    }
                    this.setState({ isLoading: false });
                })
            }
            else {
                userForm.password = userForm.mobile;
                userForm.hearAboutICT = 'ADMIN';
                userForm.role = 'user';
                UserServices.addUser(userForm).then((responce) => {
                    let { message, statusCode } = responce.data;
                    if (statusCode === 200) {
                        toast.success(message);
                        const { history } = this.props;
                        if (history) history.push('/user');
                    }
                    else {
                        toast.error(message);
                    }
                    this.setState({ isLoading: false });
                })
            }
        }
        else {
            toast.error('Please fill email and mobile number')
        }
    }
    render() {
        let { userForm, isLoading } = this.state;
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="page-header">
                        <h3 className="page-title">
                            <span className="page-title-icon bg-gradient-info text-white mr-2">
                                <i className="mdi mdi-home"></i>
                            </span> Manage User
                        </h3>
                        <nav aria-label="breadcrumb">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span></span>Overview <i
                                        className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <Loader isLoading={isLoading} />
                    <div className="row">
                        <div className="col-lg-12 grid-margin">
                            <div className="card card-box">
                                <div className="card-body card-body-pd">
                                    <div className="form-horizontal">
                                        <div className="row">
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="firstName" className="required">First Name</label>
                                                <input type="text" name="firstName"
                                                    value={userForm.firstName}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    id="firstName" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input type="text" name="lastName"
                                                    value={userForm.lastName}
                                                    onChange={this.handleChange}
                                                    className="form-control" id="lastName" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="email" className="required">Email</label>
                                                <input type="text" name="email" value={userForm.email}
                                                    onChange={this.handleChange}
                                                    className="form-control" id="email" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="mobile" className="required">Mobile</label>
                                                <div className="clearfix"></div>
                                                <IntlTelInput
                                                    value={userForm.mobile}
                                                    containerClassName="intl-tel-input"
                                                    inputClassName="form-control number"
                                                    preferredCountries={['in']}
                                                    onSelectFlag={(num, country) => {
                                                        console.log('onSelectFlag', country);
                                                    }}
                                                    onPhoneNumberChange={this.onChangeMobile}
                                                />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="dob">DOB</label>
                                                <DatePicker
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    name="dob"
                                                    id="dob"
                                                    autoComplete="off"
                                                    placeholderText="DOB"
                                                    selected={userForm.dob ? new Date(userForm.dob) : ""}
                                                    onChange={dob => this.handleChange(dob, "dob")}
                                                    required="required"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-8">
                                                <label htmlFor="address">Address</label>
                                                <input type="text" name="postalAddress"
                                                    value={userForm.postalAddress}
                                                    onChange={this.handleChange}
                                                    className="form-control" id="postalAddress" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="country">Country</label>
                                                <input type="text" name="country"
                                                    value={userForm.country}
                                                    onChange={this.handleChange}
                                                    className="form-control" id="country" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="state">State</label>
                                                <input type="text" name="state"
                                                    value={userForm.state}
                                                    onChange={this.handleChange}
                                                    className="form-control" id="state" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="city">City</label>
                                                <input type="text" name="city"
                                                    value={userForm.city}
                                                    onChange={this.handleChange}
                                                    className="form-control" id="city" />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="postcode">Postcode</label>
                                                <input type="text" name="postcode"
                                                    value={userForm.postcode}
                                                    onChange={this.handleChange}
                                                    className="form-control" id="postcode" />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" onClick={this.handleSubmit} className="btn btn-gradient-info btn-fw">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default UserComponent;