import { useState } from "react";
import BreadCrumb from "../common/BreadCrumb";
import Loader from "../common/Loader";
import { TextEditor } from '../element/TextEditor';
const inputObj = {
    type: "0",
    title: "",
    isPaid: true,
    freeTrial: false,
    freeTrialNumber: 0,
    freeTrialType: 'months',
    description: "",
    conditions: "",
    validity: []
}
const packageType = [
    { value: 0, label: 'Startup' },
    { value: 1, label: 'Executive' },
    { value: 2, label: 'Premium' },
    { value: 3, label: 'Ultimate' }
]
const typesArr = [
    { value: 'days', label: 'Days' },
    { value: 'months', label: 'Months' },
    { value: 'years', label: 'Years' }
]
const currencyArr = [
    { value: 'INR', label: 'INR' },
]
let validityObj = { number: "", type: "days", sellingPrice: "", offerPrice: "", currency: 'INR' };
function PackageManagement() {
    const [inputField, setInputField] = useState(inputObj);
    const [validityArr, setValidityArr] = useState([validityObj]);
    const [isLoading, setIsLoading] = useState(false);

    const changeHandler = (e, txt, isEditor = false) => {
        if (isEditor) {
            setInputField((prev) => ({
                ...prev,
                [e]: txt
            }))
        }
        else {
            setInputField((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }
    const addRow = (e) => {
        let newBP = [...validityArr, ...[validityObj]];
        setValidityArr(newBP);
    }
    const removeRow = (index) => {
        let newRP = [...validityArr];
        newRP.splice(+index, 1);
        setValidityArr(newRP);
    }
    const validityChange = (v, i, name) => {
        let newRP = [...validityArr];
        newRP = newRP.map((vl, index) => {
            if (i == index) {
                vl = { ...vl, ...{ [name]: v } }
            }
            return vl;
        });
        setValidityArr(newRP);
    }
    const handleSubmit = (e) => {
        inputField.validity = validityArr;
        console.log(inputField);
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <BreadCrumb title={'Add Package'} />
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body card-body-pd">
                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="type" className="required">Type</label>
                                            <select className="form-control"
                                                name="type"
                                                onChange={changeHandler}
                                                value={inputField.type}>
                                                {packageType && packageType.map((obj, i) => {
                                                    return <option key={i} value={obj.value}>{obj.label}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="title" className="required">Title</label>
                                            <input type="text" name="title"
                                                onChange={changeHandler}
                                                value={inputField.title}
                                                className="form-control"
                                                id="title" />
                                        </div>
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="isPaid" className="required">Paid Status</label>
                                            <select className="form-control"
                                                name="isPaid"
                                                value={inputField.isPaid}
                                                onChange={changeHandler}>
                                                <option value="true">Paid</option>
                                                <option value="false">Free</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="freeTrial" className="required">Free Trial</label>
                                            <select className="form-control"
                                                value={inputField.freeTrial}
                                                name="freeTrial"
                                                onChange={changeHandler}>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>
                                        <div className={inputField.freeTrial == 'true' ? "form-group col-sm-4 readonly" : "form-group col-sm-4"}>
                                            <label htmlFor="freeTrialNumber" className="">Free Trial Days / Months {inputField.freeTrial}</label>
                                            <div className="input-group">
                                                <input type="text"
                                                    name="freeTrialNumber"
                                                    className="form-control number"
                                                    value={inputField.freeTrialNumber}
                                                    onChange={changeHandler}
                                                    placeholder="Enter Free Trial Days / Months"
                                                />
                                                <div className="input-group-append">
                                                    <select className="form-control-append"
                                                        onChange={changeHandler}
                                                        value={inputField.freeTrialType}
                                                        name="freeTrialType">
                                                        {typesArr && typesArr.map((obj, index) => <option key={index} value={obj.value}>{obj.label}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 className="card-title">Plan</h4>
                                    <hr />
                                    {validityArr && validityArr.map((vl, vi) => {
                                        return (
                                            <div className="row" key={vi}>
                                                <div className="form-group col-sm-2">
                                                    <label htmlFor="number" className="required">Number</label>
                                                    <input type="text"
                                                        name="number"
                                                        className="form-control number"
                                                        value={vl['number']}
                                                        onChange={(e) => validityChange(e.target.value, vi, 'number')}
                                                    />
                                                </div>
                                                <div className="form-group col-sm-2">
                                                    <label htmlFor="type" className="required">Months</label>
                                                    <select
                                                        className="form-control"
                                                        name="type"
                                                        value={vl['type']}
                                                        onChange={(e) => validityChange(e.target.value, vi, 'type')}>
                                                        {typesArr && typesArr.map((obj, index) => {
                                                            return (<option key={index} value={obj.value}>{obj.label}</option>)
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group col-sm-2">
                                                    <label htmlFor="sellingPrice" className="required">Selling Price</label>
                                                    <input type="text"
                                                        name="sellingPrice"
                                                        className="form-control decimal"
                                                        value={vl['sellingPrice']}
                                                        onChange={(e) => validityChange(e.target.value, vi, 'sellingPrice')} />
                                                </div>
                                                <div className="form-group col-sm-2">
                                                    <label htmlFor="offerPrice" className="required">Offer Price</label>
                                                    <input type="text"
                                                        name="offerPrice"
                                                        className="form-control decimal"
                                                        value={vl['offerPrice']}
                                                        onChange={(e) => validityChange(e.target.value, vi, 'offerPrice')} />
                                                </div>
                                                <div className="form-group col-sm-2">
                                                    <label htmlFor="currency" className="required">Currency</label>
                                                    <select className="form-control"
                                                        name="currency"
                                                        value={vl['currency']}
                                                        onChange={(e) => validityChange(e.target.value, vi, 'currency')}>
                                                        {currencyArr && currencyArr.map((obj, index) => <option key={index} value={obj.value}>{obj.label}</option>)}
                                                    </select>
                                                </div>
                                                <div className="form-group col-sm-2">
                                                    <button onClick={addRow} className="btn btn-gradient-success btn-rounded btn-icon">
                                                        <i className="mdi mdi-plus"></i>
                                                    </button>
                                                    {validityArr.length > 1 ?
                                                        <button onClick={(e) => removeRow(vi)} className="btn btn-gradient-danger btn-rounded btn-icon">
                                                            <i className="mdi mdi-minus"></i>
                                                        </button> : null}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="row">
                                        <div className="form-group col-sm-12">
                                            <label htmlFor="description" className="required">Description</label>
                                            <TextEditor
                                                initialContent={inputField.description}
                                                name='description'
                                                onChange={changeHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-sm-12">
                                            <label htmlFor="conditions" className="required">Terms and Condition</label>
                                            <TextEditor
                                                initialContent={inputField.conditions}
                                                name='conditions'
                                                onChange={changeHandler}
                                            />
                                        </div>
                                    </div>
                                    <button type="button" onClick={handleSubmit} className="btn btn-gradient-info btn-fw">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PackageManagement;