import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATE_FORMAT_INPUT } from "../../config/constant";
const Calendra = ({ className = 'form-control', value, onChange, name, index }) => {
    return (
        <DatePicker
            className={className}
            selected={value ? new Date(value) : null}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            onChange={(date) => onChange(date, name, index)}
            dropdownMode="select"
            placeholderText="--/--/--"
            dateFormat={DATE_FORMAT_INPUT}
        />
    );
}
export { Calendra }