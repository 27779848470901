import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import { CONTENT_TYPES, REDIRECT_TYPE } from "../../config/constant"
import Loader from '../common/Loader';
const formObj = {
    title: "",
    description: "",
    position: 0,
    displayAt: "",
    image: "",
    redirectType: "_blank",
    targetUrl: ""
}
function BannerComponent() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [inputField, setInputField] = useState(formObj);
    const [selectedFile, setSelectedFile] = useState();
    const [bannerTypes, setBannerTypes] = useState(CONTENT_TYPES);
    const [isFilePicked, setIsFilePicked] = useState(false);
    let { bannerId } = useParams();
    useEffect(() => {
        brandDetail();
    }, [])
    const brandDetail = async () => {
        if (bannerId) {
            setIsLoading(true);
            await UserServices.bannerDetail({ bannerId }).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode === 200) {
                    let formObj = {
                        title: data.title,
                        description: data.description,
                        position: data.position,
                        displayAt: data.displayAt,
                        redirectType: data.redirectType,
                        targetUrl: data.targetUrl,
                    }
                    setInputField({ ...inputField, ...formObj });
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
    };
    const inputHandler = (e) => {
        if (e.target.files) {
            setSelectedFile(e.target.files[0]);
            setIsFilePicked(true);
        }
        else {
            setInputField({ ...inputField, [e.target.name]: e.target.value });
        }
    }
    const submitForm = async (e) => {
        e.preventDefault();
        if (inputField.title != "" && inputField.displayAt != "") {
            const formData = new FormData();
            formData.append('title', inputField.title);
            formData.append('description', inputField.description);
            formData.append('position', inputField.position);
            formData.append('displayAt', inputField.displayAt);
            formData.append('image', selectedFile);
            formData.append('redirectType', inputField.redirectType);
            formData.append('targetUrl', inputField.targetUrl);
            if (bannerId) {
                setIsLoading(true);
                formData.append('bannerId', bannerId);
                UserServices.updateBanner(formData).then((responce) => {
                    let { message, statusCode } = responce.data;
                    if (statusCode == 200) {
                        toast.success(message);
                        if (history) history.push('/banner');
                    }
                    else {
                        toast.error(message);
                    }
                    setIsLoading(false);
                })
            }
            else {
                if (isFilePicked) {
                    setIsLoading(true);
                    UserServices.addBanner(formData).then((responce) => {
                        let { message, statusCode } = responce.data;
                        if (statusCode == 200) {
                            toast.success(message);
                            if (history) history.push('/banner');
                        }
                        else {
                            toast.error(message);
                        }
                        setIsLoading(false);
                    })
                }
            }
        }
        else {
            toast.error('Please fill all the required fields')
        }
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Banner
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card card-box">
                            <div className="card-body card-body-pd">
                                <form encType="multipart/form-data">
                                    <div className="form-horizontal">
                                        <div className="row">
                                            <div className="form-group col-sm-5">
                                                <label htmlFor="title" className="required">Title</label>
                                                <input type="text" name="title"
                                                    value={inputField.title}
                                                    onChange={inputHandler}
                                                    className="form-control" id="title" required />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="position" className="required">Position</label>
                                                <input type="text" name="position"
                                                    value={inputField.position}
                                                    onChange={inputHandler}
                                                    className="form-control" id="position" required />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="displayAt" className="required">Banner For</label>
                                                <select className="form-control" id="displayAt"
                                                    name="displayAt"
                                                    value={inputField.displayAt}
                                                    onChange={inputHandler}>
                                                    <option value="">Please select banner for</option>
                                                    {bannerTypes && bannerTypes.map((btype, indx) => {
                                                        return <option key={indx} value={btype.key}>{btype.label}</option>
                                                    })}
                                                </select>
                                            </div>
                                            {/* <div className="form-group col-sm-3">
                                                <label htmlFor="startDate">Start Date</label>
                                                <input type="date" name="startDate"
                                                    value={inputField.startDate}
                                                    onChange={inputHandler}
                                                    className="form-control" id="startDate" required />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="endDate">End Date</label>
                                                <input type="date" name="endDate"
                                                    value={inputField.endDate}
                                                    onChange={inputHandler}
                                                    className="form-control" id="endDate" required />
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="redirectType">Redirect Type</label>
                                                <select className="form-control" id="redirectType"
                                                    name="redirectType"
                                                    value={inputField.redirectType}
                                                    onChange={inputHandler}>
                                                    {REDIRECT_TYPE && REDIRECT_TYPE.map((btype, indx) => {
                                                        return <option key={indx} value={btype.key}>{btype.label}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-9">
                                                <label htmlFor="targetUrl">Target Url</label>
                                                <input type="text" name="targetUrl"
                                                    value={inputField.targetUrl}
                                                    onChange={inputHandler}
                                                    className="form-control" id="targetUrl" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="description">Description</label>
                                                <input type="text" name="description"
                                                    value={inputField.description}
                                                    onChange={inputHandler}
                                                    className="form-control" id="description" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="image" className="required">Image</label>
                                                <input type="file" name="image"
                                                    onChange={inputHandler}
                                                    className="form-control" id="image" required />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" onClick={submitForm} className="btn btn-gradient-info btn-fw">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerComponent;