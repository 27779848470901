import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import Loader from '../common/Loader';
import Modal from '../element/Modal';
const defaultForm = {
    querySubject: "",
    queryReply: "",
    replyDate: new Date()
}
function ConatctList() {
    const [contactus, setContactus] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [contactModal, setContactModal] = useState(false);
    const [selectedContact, setSelectedContact] = useState({});
    const [error, setError] = useState({});
    const [replyForm, setReplyForm] = useState(defaultForm);

    useEffect(() => {
        getAllContactus();
    }, [])
    const getAllContactus = () => {
        UserServices.contactusList({}).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setContactus(data.contacts);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const openModal = (cObj) => {
        setSelectedContact(cObj);
        setContactModal(true);
        document.body.classList.add('modal-open');
    }
    const closeModal = () => {
        setContactModal(false);
        setReplyForm(defaultForm);
        document.body.classList.remove('modal-open')
    }
    const isValidateForm = () => {
        error.querySubject = replyForm.querySubject ? "" : "error";
        error.queryReply = replyForm.queryReply ? "" : "error";
        setError({ ...error })
        if (error.querySubject || error.queryReply) {
            return false;
        }
        return true
    }
    const submitForm = () => {
        if (isValidateForm()) {
            replyForm.queryId = selectedContact._id;
            UserServices.replyContactus(replyForm).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode == 200) {
                    getAllContactus();
                    toast.success(message);
                    closeModal();
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
    }
    const inputChangeHandler = (e) => {
        setReplyForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Contact Us
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>Company Name</th>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>Message</th>
                                                <th>CreatedAt</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contactus && contactus.map((btype, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>{btype.companyName}</td>
                                                        <td>{btype.firstName}</td>
                                                        <td>{btype.email}</td>
                                                        <td>{btype.dialCode + ' ' + btype.mobile}</td>
                                                        <td>{btype.message}</td>
                                                        <td>{btype.createdAt}</td>
                                                        <td>
                                                            {btype.queryStatus == 'pending' ?
                                                                <label className="badge badge-gradient-warning">PROGRESS</label>
                                                                : (
                                                                    btype.queryStatus == 'rejected' ?
                                                                        <label className="badge badge-gradient-danger">REJECTED</label> : <label className="badge badge-gradient-success">DONE</label>
                                                                )
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                                <button type="button" onClick={() => openModal(btype)} className="btn btn-gradient-success btn-sm">
                                                                    <i className="mdi mdi-reply"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={contactModal} handleClose={() => { closeModal() }} title={"Reply to -> " + selectedContact.email}>
                    <div className={error.querySubject ? "form-group form-error" : "form-group"}>
                        <label for="subject" className="required">Subject</label>
                        <input type="text" onChange={inputChangeHandler} className="form-control" name="querySubject" value={replyForm.querySubject || ''} id="subject" placeholder="Enter your subject" />
                    </div>
                    <div className={error.queryReply ? "form-group form-error" : "form-group"}>
                        <label for="message" className="required">Message</label>
                        <textarea className="form-control" onChange={inputChangeHandler} id="message" name="queryReply" value={replyForm.queryReply || ''} placeholder="Enter your message" rows="4"></textarea>
                    </div>
                    <div className="float-right">
                        <button type="button" onClick={submitForm} className="btn btn-gradient-info btn-sw"><i className="mdi mdi-replay"></i> Reply</button>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default ConatctList;