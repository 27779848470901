import { useState, useEffect } from "react";
import BreadCrumb from "../common/BreadCrumb";
import { API_URL } from "../../config/constant";
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from '../common/Loader';
import UserSearch from './UserSearch';
import { displayDate } from "../../utils"
import Pagination from '../element/Pagintion';
import axios from 'axios'

function NewsLetterSubscriber() {
    let [isLoading, setIsLoading] = useState();
    const [users, setUsers] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [totalUsers, setTotalUsers] = useState(0);
    const [page, setPage] = useState(1);
    const [checkedUsers, setCheckedUsers] = useState([]);
    const [subject, setSubject] = useState('Default Subject');
    const [emailContent, setEmailContent] = useState('Default Email Content');

    useEffect(() => {
        const URL = "newsletter/list";
        setIsLoading(true); // Set isLoading to true before the fetch

        fetch(`${API_URL.COMMON_GATEWAY_API}${URL}`)
        .then((res) => res.json())
        .then((data) => {
            setUsers(data);
            setIsLoading(false); // Set isLoading to false after the fetch (whether it succeeds or fails)
        })
        .catch((error) => {
            console.log(error);
            setIsLoading(false); // Set isLoading to false in case of an error
        });
        // getAllUsers({}, 1);
      }, []);
    const getAllUsers = (searchObj, pageNo = page) => {
        let obj = { ...searchObj, role: "user", page: pageNo };
        UserServices.allUserList(obj).then((responce) => {
          let { data, message, statusCode } = responce.data;
          if (statusCode === 200) {
            setUsers(...users, data.users);
            setTotalUsers(data.total);
          } else {
            toast.error(message);
          }
          setIsLoading(false);
        });
    };
      const inputHandler = (e) => {
        setFilterData({ ...filterData, [e.target.name]: e.target.value });
      };
      const searchFromSubmit = (e) => {
        if (filterData) {
          setIsLoading(true);
          getAllUsers(filterData, page);
        }
      };
      const handlePageClick = (n) => {
        setPage(n);
        getAllUsers({}, n);
      };
      const sendSelectedEmails = () => {
        // console.log(checkedUsers);
        // axios.post("http://localhost:8182/api/newsletter/send-mails", checkedUsers);
        const requestBody = {
          users: checkedUsers,
          subject: subject,
          emailContent: emailContent,
        };
        UserServices.sendMail(requestBody).then((responce) => {
            let { message } = responce.data;
            if (responce.status == 200) {
              toast.success(message);
              // getAllUsers({}, page);
            } else {
              toast.error(message);
              setIsLoading(false);
            }
        });
      };
    
      const sendAllEmails = () => {
        // axios.post("https://localhost:8000/api/send-bulk-mails", users);
        const requestBody = {
          users: users,
          subject: subject,
          emailContent: emailContent,
        };
        UserServices.sendMail(requestBody).then((responce) => {
            let { message } = responce.data;
            console.log(responce.data);
            if (responce.status === 200) {
              toast.success(message);
              // getAllUsers({}, page);
            } else {
              toast.error(message);
              setIsLoading(false);
            }
        });
      };
    
      const handleCheckboxChange = (e, user) => {
        const isChecked = e.target.checked;
        if (isChecked) {
          setCheckedUsers((prevCheckedUsers) => [...prevCheckedUsers, user]);
        } else {
          setCheckedUsers((prevCheckedUsers) =>
            prevCheckedUsers.filter((u) => u.id !== user.id)
          );
        }
      };
    return (
        // <div className="main-panel">
        //     <div className="content-wrapper">
        //         <BreadCrumb title={'Newsletter Subscriber'} />
        //         <Loader isLoading={isLoading} />
        //         <div className="row">
        //             <div className="col-lg-12 grid-margin">
        //                 <div className="card mt-card">
        //                     <div className="card-body">
        //                         <div className="table-responsive">
        //                             <table className="table table-striped mat-table">
        //                                 <thead>
        //                                     <tr className="mat-header">
        //                                         <th>S.No.</th>
        //                                         <th>Email</th>
        //                                         <th>Subscrib On</th>
        //                                         <th>Status</th>
        //                                         <th>Action</th>
        //                                     </tr>
        //                                 </thead>
        //                                 <tbody>
        //                                     <tr>
        //                                         <td>1</td>
        //                                         <td>1</td>
        //                                         <td>1</td>
        //                                         <td>1</td>
        //                                         <td>1</td>
        //                                     </tr>
        //                                 </tbody>
        //                             </table>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="main-panel">
        <div className="content-wrapper">
            <BreadCrumb title={"Newsletter Subscriber"} />
            <Loader isLoading={isLoading} />

            <div className="row">
            <div className="col-lg-12 grid-margin">
                <div className="card mt-card">
                <div className="card-body">
                    <div className="table-responsive">
                    <table className="table table-striped mat-table">
                        <thead>
                        <tr className="mat-header">
                            <th>S.No.</th>
                            <th>Email</th>
                            <th>Subscribed On</th>
                            {/* <th>Status</th> */}
                            <th>Select</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user, index) => (
                            <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.email}</td>
                            <td>{displayDate(user.created_at)}</td>
                            {/* <td>
                                {user.status == true ? (
                                <label className="switch switch-3d switch-primary grid-switch-1">
                                    <input
                                    type="checkbox"
                                    onChange={(evnt) =>
                                        changeStatus(evnt, user._id)
                                    }
                                    className="switch-input"
                                    checked={user.status || false}
                                    />
                                    <span className="switch-slider"></span>
                                </label>
                                ) : (
                                <label className="switch switch-3d switch-primary grid-switch-1">
                                    <input
                                    type="checkbox"
                                    onChange={(evnt) =>
                                        changeStatus(evnt, user._id)
                                    }
                                    checked={user.status || false}
                                    className="switch-input"
                                    />
                                    <span className="switch-slider"></span>
                                </label>
                                )}
                            </td> */}
                            <td>
                                {" "}
                                <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e, user)}
                                />
                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="text-center">
            <div className="row">
                <div className="col-md-6-lg-5 mx-auto">
                <button className="btn btn-primary" onClick={sendSelectedEmails}>
                    Send Mails to Selected
                </button>
                </div>
                <div className="col-md-6-lg-5 mx-auto">
                <button className="btn btn-danger" onClick={sendAllEmails}>
                    Send Mails to All
                </button>
                </div>
            </div>
            </div>
            <div className="pagination-container container">
            <Pagination
                onPaginate={handlePageClick}
                total={totalUsers}
                perPage={50}
            />
            </div>
        </div>
        <div className="row content-wrapper">
          <div className="col-8 col-md-6-lg-5 mx-auto">
            <div className="form-group">
              <label htmlFor="subject">Subject:</label>
              <input
                type="text"
                id="subject"
                className="form-control"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Subject"
              />
            </div>
            <div className="form-group">
              <label htmlFor="emailContent">Email Body:</label>
              <textarea
                id="emailContent"
                className="form-control"
                rows="4"
                value={emailContent}
                onChange={(e) => setEmailContent(e.target.value)}
                placeholder="Email Body"
              />
            </div>
          </div>
        </div>
        </div>
    )
}
export default NewsLetterSubscriber;