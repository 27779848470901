import { Tabs, Tab } from "react-bootstrap";
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";
import { useEffect, useState } from "react";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import UserSellerProfile from "../user/UserSellerProfile";

function AdminProfile() {
    const [loginUSER, setLoginUSER] = useState({});
    useEffect(() => {
        const loggedInUser = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        if (loggedInUser && loggedInUser.user) {
            userDetail(loggedInUser.user._id);
        }
    }, [])
    const userDetail = async (adminId) => {
        UserServices.adminDetail({ adminId: adminId }).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setLoginUSER(data)
            }
            else {
                toast.error(message);
            }
        })
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Profile
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <Tabs defaultActiveKey="updateProfile" transition={false} id="noanim-tab-example" className="mb-3 custome-nav">
                                            <Tab eventKey="updateProfile" title="Update Profile">
                                                <UpdateProfile loginUser={loginUSER} />
                                            </Tab>
                                            <Tab eventKey="changePassword" title="Change Password">
                                                <ChangePassword loginUser={loginUSER} />
                                            </Tab>
                                        </Tabs>
                                    </div>
                                    <div className="col-lg-3">
                                        <UserSellerProfile userProf={loginUSER} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminProfile;