import axios from "axios";
import { API_URL } from "../config/constant";
import secureStorage from "../config/encrypt";
// import { toast } from 'react-toastify';
axios.interceptors.response.use(
    response => {
        if (response.data && response.data.status !== 200) {
            // console.error(`${response.data.status} - ${response.data.message}`, response.data)
            // if(response.data.status == 400)
            //     window.commonErrorToast(response.data.message)
            //     toast.error(`${response.data.status} - ${response.data.message}`);
        }
        return response;
    },
    error => {
        // handle error
        if (error.response) {
            // console.error(`${error.response.status} - ${error.response.statusText}`, error.response)
            if (error.response.status === 503) {
                // toast.error(`${error.response.status} - ${error.response.statusText}`);
            } else if (error.response.status === 401) {
                // if(error.response.data.errors == "TokenEmptyInCache")
                //toast.error(`${error.response.data.status} - ${error.response.data.message}`);
                window.location.href = "/logout";
            }
        }

        return error.response;
    }
);

export default {
    getToken() {
        let fetchHeader = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY) ? secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY) : {};
        return fetchHeader && fetchHeader.data && fetchHeader.data.token ? fetchHeader.data.token : null;
    },
    post(URL, body, headers) {
        headers = headers || {};
        headers["Authorization"] = 'Basic ' + btoa('ictkartvender' + ':' + 'RAO@8HV!e*ZO6~x5a3y');
        // this.getToken();
        // headers["Accept-Language"] = secureStorage.getItem("lang");
        URL = URL[0] === "/" ? URL.substr(1) : URL;
        return axios.post(`${API_URL.COMMON_GATEWAY_API}${URL}`, body, { headers });
    },
    put(URL, body, headers) {
        headers = headers || {};
        headers["Authorization"] = 'Basic ' + btoa('ictkartvender' + ':' + 'RAO@8HV!e*ZO6~x5a3y');
        // headers["Accept-Language"] = secureStorage.getItem("lang");
        // URL = URL[0] === "/" ? URL.substr(1) : URL;
        return axios.put(`${API_URL.COMMON_GATEWAY_API}${URL}`, body, { headers });
    },
    get(URL, query, headers) {
        headers = headers || {};
        headers["Authorization"] = 'Basic ' + btoa('ictkartvender' + ':' + 'RAO@8HV!e*ZO6~x5a3y');
        // headers["Accept-Language"] = secureStorage.getItem("lang");
        URL = URL[0] === "/" ? URL.substr(1) : URL;
        return axios.get(`${API_URL.COMMON_GATEWAY_API}${URL}`, {
            params: query,
            headers
        });
    }
};
