import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Select from 'react-select';
import { UserServices } from "../../services";
import { CATEGORY_TYPES } from "../../config/constant";
import Loader from '../common/Loader';
const formObj = {
    title: "",
    parentId: null,
    type: "product",
    description: "",
    position: 0,
    image: "",
    icon: "",
    urlKey: "",
    metaTitle: "",
    keyWords: "",
    metaDescription: "",
    serviceSubCategory: []
}
function ProductCategoryComponent() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [inputField, setInputField] = useState(formObj);
    const [categories, setCategories] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [selectedIconFile, setSelectedIconFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    let { categoryId, type } = useParams();
    const [serviceCategories, setServiceCategories] = useState([]);
    const [serviceSubCategories, setServiceSubCCategories] = useState([]);
    useEffect(() => {
        if (type) {
            categoryDetail();
            categoryList(type);
            setInputField((prev) => ({ ...prev, type: type }));
            if (type == 'product') serviceCategoryList();
        }
    }, [type])
    const categoryList = async () => {
        await UserServices.categoriesDropDownList(type).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                if (data && data.list && data.list.length) {
                    let cateArr = [];
                    data.list.map(c => {
                        cateArr.push(c);
                        c.subcategories.map(s => {
                            s.label = s.label + ' (' + c.label + ')';
                            cateArr.push(s);
                            return s;
                        })
                        delete c.subcategories;
                        return c
                    })
                    setCategories(cateArr);
                }
            }
            else {
                toast.error(message);
            }
        })
    };
    const serviceCategoryList = async () => {
        await UserServices.subCategoriesDropDownList('service').then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                setServiceSubCCategories(data.list);
            }
            else {
                toast.error(message);
            }
        })
    };
    const categoryDetail = async () => {
        if (categoryId) {
            await UserServices.categoryDetail(categoryId).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode === 200) {
                    setInputField({ ...inputField, ...data.category });
                    console.log(inputField);
                }
                else {
                    toast.error(message);
                }
            })
        }
    };
    const inputHandler = (e, name = "") => {
        if (name === '' && e.target.files) {
            setSelectedFile(e.target.files[0]);
            // setIsFilePicked(true);
        }
        else if (name !== "") {
            let parentId = e ? e.value : null;
            setInputField({ ...inputField, parentId: parentId });
        }
        else {
            setInputField({ ...inputField, [e.target.name]: e.target.value });
        }
    }
    const iconFileHandler = (e) => {
        if (e.target.files) {
            setSelectedIconFile(e.target.files[0]);
            setIsFilePicked(true);
        }
    }
    const submitForm = async (e) => {
        if (inputField.title != "" && inputField.type != "") {
            setIsLoading(true);
            e.preventDefault();
            const formData = new FormData();
            formData.append('title', inputField.title);
            formData.append('parentId', inputField.parentId);
            formData.append('type', inputField.type);
            formData.append('urlKey', inputField.urlKey);
            formData.append('metaTitle', inputField.metaTitle);
            formData.append('keyWords', inputField.keyWords);
            formData.append('metaDescription', inputField.metaDescription);
            formData.append('description', inputField.description);
            formData.append('position', inputField.position);
            formData.append('serviceSubCategory', JSON.stringify(inputField.serviceSubCategory));
            formData.append('image', selectedFile);
            formData.append('icon', selectedIconFile);

            if (categoryId) {
                formData.append('categoryId', categoryId);
                UserServices.updateCategories(formData).then((responce) => {
                    let { data, message, statusCode } = responce.data;
                    if (statusCode == 200) {
                        toast.success(message);
                        if (history) history.push(`/${type}/category`);
                    }
                    else {
                        toast.error(message);
                    }
                    setIsLoading(false);
                })
            }
            else {
                if (isFilePicked) {
                    UserServices.addCategories(formData).then((responce) => {
                        let { data, message, statusCode } = responce.data;
                        if (statusCode == 200) {
                            toast.success(message);
                            if (history) history.push(`/${type}/category`);
                        }
                        else {
                            toast.error(message);
                        }
                        setIsLoading(false);
                    })
                }
            }
        }
        else {
            toast.error('Please fill all the required field');
        }
    }
    const serviceCategoryHandler = (name, v) => {
        let vv = v.map(obj => obj.value);
        setInputField({ ...inputField, 'serviceSubCategory': vv });
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span>Manage Category
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card card-box">
                            <div className="card-body card-body-pd">
                                <form encType="multipart/form-data">
                                    <div className="form-horizontal">
                                        <div className="row">
                                            <div className="form-group col-sm-5">
                                                <label htmlFor="position">Parent Category</label>
                                                <Select
                                                    value={categories.filter(obj => inputField.parentId === (obj.value))}
                                                    onChange={inputHandler}
                                                    options={categories}
                                                    isClearable
                                                />
                                            </div>
                                            {/* <div className="form-group col-sm-3">
                                                <label htmlFor="type" className="required">Type</label>
                                                <select className="form-control" id="type"
                                                    name="type"
                                                    value={inputField.type}
                                                    onChange={inputHandler}>
                                                    {CATEGORY_TYPES && CATEGORY_TYPES.map((btype, indx) => {
                                                        return <option key={indx} value={btype.value}>{btype.label}</option>
                                                    })}
                                                </select>
                                            </div> */}
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="title" className="required">Title</label>
                                                <input type="text" name="title"
                                                    value={inputField.title}
                                                    onChange={inputHandler}
                                                    className="form-control" id="title" required />
                                            </div>
                                            <div className="form-group col-sm-1">
                                                <label htmlFor="position">Position</label>
                                                <input type="text" name="position"
                                                    value={inputField.position}
                                                    onChange={inputHandler}
                                                    className="form-control" id="position" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="description">Description</label>
                                                <input type="text" name="description"
                                                    value={inputField.description}
                                                    onChange={inputHandler}
                                                    className="form-control" id="description" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="icon" className="required">Icon</label>
                                                <input type="file" name="icon"
                                                    onChange={iconFileHandler}
                                                    className="form-control" id="icon" required />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="image">Image</label>
                                                <input type="file" name="image"
                                                    onChange={inputHandler}
                                                    className="form-control" id="image" required />
                                            </div>
                                        </div>
                                        {type == 'product' ?
                                            <>
                                                <h4>Related Services</h4>
                                                <hr />
                                                <div className="row">
                                                    {/* <div className="form-group col-sm-6">
                                                        <label htmlFor="position">Service Parent Category</label>
                                                        <Select
                                                            value={serviceCategories.filter(obj => inputField.serviceCategory.includes(obj.value))}
                                                            onChange={(e) => serviceCategoryHandler('serviceCategory', e)}
                                                            options={serviceCategories}
                                                            isClearable
                                                            isMulti
                                                        />
                                                    </div> */}
                                                    <div className="form-group col-sm-12">
                                                        <label htmlFor="position">Service Sub Category</label>
                                                        <Select
                                                            value={serviceSubCategories.filter(obj => inputField.serviceSubCategory.includes(obj.value))}
                                                            onChange={(e) => serviceCategoryHandler('serviceSubCategory', e)}
                                                            options={serviceSubCategories}
                                                            isClearable
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                        <h4>Search Engine Optimization</h4>
                                        <hr />
                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="urlKey">URL KEY</label>
                                                <input type="text" name="urlKey"
                                                    value={inputField.urlKey}
                                                    onChange={inputHandler}
                                                    className="form-control" id="urlKey" />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="metaTitle">META TITLE</label>
                                                <input type="text" name="metaTitle"
                                                    value={inputField.metaTitle}
                                                    onChange={inputHandler}
                                                    className="form-control" id="metaTitle" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="keyWords">META KEYWORDS</label>
                                                <textarea name="keyWords"
                                                    value={inputField.keyWords}
                                                    onChange={inputHandler}
                                                    className="form-control" id="keyWords" rows="4"></textarea>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="metaDescription">META DESCRIPTION</label>
                                                <textarea name="metaDescription"
                                                    value={inputField.metaDescription}
                                                    onChange={inputHandler}
                                                    className="form-control" id="metaDescription" rows="4"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" onClick={submitForm} className="btn btn-gradient-info btn-fw">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCategoryComponent;