import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
function HomeComponent() {
    const [dashboard, setDashboard] = useState({
        user: 0,
        seller: 0,
        stock: 0,
        sales: 0,
        orders: 0,
    });
    useEffect(() => {
        getDashboard();
    }, [])
    const getDashboard = () => {
        UserServices.dashboard().then((responce) => {
            let { data, message } = responce.data;
            setDashboard(data);
        })
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Dashboard
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-md-3 stretch-card grid-margin">
                        <div className="card bg-gradient-danger card-img-holder text-white">
                            <div className="card-body">
                                <img src={require("../../webroot/images/circle.svg").default} className="card-img-absolute" alt="circle-image" />
                                <h4 className="font-weight-normal mb-3">Total Orders <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                                </h4>
                                <h2 className="mb-5">{dashboard.orders}</h2>
                                <h6 className="card-text"></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 stretch-card grid-margin">
                        <div className="card bg-gradient-info card-img-holder text-white">
                            <div className="card-body">
                                <img src={require("../../webroot/images/circle.svg").default} className="card-img-absolute" alt="circle-image" />
                                <h4 className="font-weight-normal mb-3">Total Sales<i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                                </h4>
                                <h2 className="mb-5">{dashboard.sales}</h2>
                                <h6 className="card-text"></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 stretch-card grid-margin">
                        <div className="card bg-gradient-success card-img-holder text-white">
                            <div className="card-body">
                                <img src={require("../../webroot/images/circle.svg").default} className="card-img-absolute" alt="circle-image" />
                                <h4 className="font-weight-normal mb-3">Total Stock <i className="mdi mdi-diamond mdi-24px float-right"></i>
                                </h4>
                                <h2 className="mb-5">{dashboard.stock ? dashboard.stock : '0'}</h2>
                                <h6 className="card-text"></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 stretch-card grid-margin">
                        <div className="card bg-gradient-primary card-img-holder text-white">
                            <div className="card-body">
                                <img src={require("../../webroot/images/circle.svg").default} className="card-img-absolute" alt="circle-image" />
                                <h4 className="font-weight-normal mb-3">Total Seller <i className="mdi mdi-diamond mdi-24px float-right"></i>
                                </h4>
                                <h2 className="mb-5">{dashboard.seller ? dashboard.seller : '0'}</h2>
                                <h6 className="card-text"></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 stretch-card grid-margin">
                        <div className="card bg-gradient-success card-img-holder text-white">
                            <div className="card-body">
                                <img src={require("../../webroot/images/circle.svg").default} className="card-img-absolute" alt="circle-image" />
                                <h4 className="font-weight-normal mb-3">Total User <i className="mdi mdi-diamond mdi-24px float-right"></i>
                                </h4>
                                <h2 className="mb-5">{dashboard.user ? dashboard.user : '0'}</h2>
                                <h6 className="card-text"></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeComponent;