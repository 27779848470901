import { Link } from 'react-router-dom';
function LeftSideBar() {
    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav scroll-side-nav">
                <li className="nav-item nav-profile">
                    <a href="#" className="nav-link">
                        <div className="nav-profile-image">
                            <img src={require("../../webroot/images/badge1.png").default} alt="profile" />
                            <span className="login-status online"></span>
                        </div>
                        <div className="nav-profile-text d-flex flex-column">
                            <span className="font-weight-bold mb-2">Admin</span>
                            <span className="text-secondary text-small">Project Manager</span>
                        </div>
                        <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                    </a>
                </li>
                <li className="nav-item">
                    <Link className="nav-link parent" to="/" activeClassName="active">
                        <img className="nav-link-img" src={require("../../webroot/images/home.png").default} />
                        <span className="menu-title">Dashboard</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link parent" to="/analytics">
                        <img className="nav-link-img" src={require("../../webroot/images/analytics.png").default} />
                        <span className="menu-title">Analytics</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link parent" to="/user">
                        <img className="nav-link-img" src={require("../../webroot/images/user.png").default} />
                        <span className="menu-title">Buyer management</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link parent" to="/seller">
                        <img className="nav-link-img" src={require("../../webroot/images/seller.png").default} />
                        <span className="menu-title">Seller management</span>
                    </Link>
                </li>
                <li className="nav-item collapse-nav-item">
                    <a className="nav-link toggle" data-toggle="collapse" href="#ui-product" aria-expanded="false" aria-controls="ui-product">
                        <img className="nav-link-img" src={require("../../webroot/images/product-management.png").default} />
                        <span className="menu-title">Product Management</span>
                        <i className="menu-arrow"></i>
                    </a>
                    <div className="collapse" id="ui-product">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link className="nav-link" to={"/product/category"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/categories.png").default} />
                                    <span className="menu-title">Category management</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to={"/manufactur"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/manufacture.png").default} />
                                    <span className="menu-title">Manufacturer management</span>
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to={"/brand"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/brand.png").default} />
                                    <span className="menu-title">Brand management</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/products"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/product.png").default} />
                                    <span className="menu-title">Product List</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item"> <Link className="nav-link" to={"/products/in-cart"}>Product in Cart</Link></li>
                            <li className="nav-item"> <Link className="nav-link" to={"/products/return"}>Return Product By User</Link></li> */}
                        </ul>
                    </div>
                </li>
                <li className="nav-item collapse-nav-item">
                    <a className="nav-link toggle" data-toggle="collapse" href="#ui-services" aria-expanded="false" aria-controls="ui-services">
                        <img className="nav-link-img" src={require("../../webroot/images/service.png").default} />
                        <span className="menu-title">Service Management</span>
                        <i className="menu-arrow"></i>
                    </a>
                    <div className="collapse" id="ui-services">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link className="nav-link" to={"/service/category"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/categories.png").default} />
                                    <span className="menu-title">Category management</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/service"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/product.png").default} />
                                    <span className="menu-title">Service List</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
                {/* <li className="nav-item collapse-nav-item">
                    <a className="nav-link toggle" data-toggle="collapse" href="#ui-solutions" aria-expanded="false" aria-controls="ui-solutions">
                        <img className="nav-link-img" src={require("../../webroot/images/solution.png").default} />
                        <span className="menu-title">Solution Management</span>
                        <i className="menu-arrow"></i>
                    </a>
                    <div className="collapse" id="ui-solutions">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link className="nav-link" to={"/solution/category"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/categories.png").default} />
                                    <span className="menu-title">Category management</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li> */}
                <li className="nav-item collapse-nav-item">
                    <a className="nav-link toggle" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                        <img className="nav-link-img" src={require("../../webroot/images/home.png").default} />
                        <span className="menu-title">Reviews</span>
                        <i className="menu-arrow"></i>
                    </a>
                    <div className="collapse" id="ui-basic">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className="nav-link" to={"/review/product"}>Product</Link></li>
                            <li className="nav-item"> <Link className="nav-link" to={"/review/vender"}>vender</Link></li>
                        </ul>
                    </div>
                </li>
                <li className="nav-item collapse-nav-item">
                    <a className="nav-link toggle" data-toggle="collapse" href="#ui-reports" aria-expanded="false" aria-controls="ui-reports">
                        <img className="nav-link-img" src={require("../../webroot/images/home.png").default} />
                        <span className="menu-title">Reports</span>
                        <i className="menu-arrow"></i>
                    </a>
                    <div className="collapse" id="ui-reports">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className="nav-link" to={"/transactions/product"}>Product Transactions</Link></li>
                            <li className="nav-item"> <Link className="nav-link" to={"/transactions/service"}>Service Transactions</Link></li>
                            {/* <li className="nav-item"> <Link className="nav-link" to={"/buy-packages"}>Buy Packages</Link></li> */}
                        </ul>
                    </div>
                </li>
                {/* <li className="nav-item collapse-nav-item">
                    <a className="nav-link toggle" data-toggle="collapse" href="#ui-package" aria-expanded="false" aria-controls="ui-package">
                        <img className="nav-link-img" src={require("../../webroot/images/home.png").default} />
                        <span className="menu-title">Package</span>
                        <i className="menu-arrow"></i>
                    </a>
                    <div className="collapse" id="ui-package">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className="nav-link" to={"/package"}>Package List</Link></li>
                            <li className="nav-item"> <Link className="nav-link" to={"/package/add"}>Add Package</Link></li>
                        </ul>
                    </div>
                </li> */}
                <li className="nav-item">
                    <Link className="nav-link parent" to="/subscribers">
                        <img className="nav-link-img" src={require("../../webroot/images/mail.png").default} />
                        <span className="menu-title">Newsletter Subscriber</span>
                    </Link>
                </li>
                <li className="nav-item collapse-nav-item">
                    <a className="nav-link toggle" data-toggle="collapse" href="#ui-general" aria-expanded="false" aria-controls="ui-general">
                        <img className="nav-link-img" src={require("../../webroot/images/product.png").default} />
                        <span className="menu-title">General Setting</span>
                        <i className="menu-arrow"></i>
                    </a>
                    <div className="collapse" id="ui-general">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link className="nav-link" to={"/contact"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/contact.png").default} />
                                    <span className="menu-title">Contact Us</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/faqs"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/faq.png").default} />
                                    <span className="menu-title">FAQs management</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/banner"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/banner.png").default} />
                                    <span className="menu-title">Banner management</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/promotion-banner"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/banner.png").default} />
                                    <span className="menu-title">Promotion Banner</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/content"}>
                                    <img className="nav-link-img" src={require("../../webroot/images/content.png").default} />
                                    <span className="menu-title">Content management</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
                {/* <li className="nav-item">
                    <Link className="nav-link" to="/product/category">
                        <span className="menu-title">Notification Management</span>
                        <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/product/category">
                        <span className="menu-title">Payment management</span>
                        <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                    </Link>
                </li> */}
            </ul>
        </nav>
    );
}

export default LeftSideBar;