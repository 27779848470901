import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from '../common/Loader';
function ContentListController() {
    const [contents, setContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getAllContents();
    }, [])
    const getAllContents = () => {
        UserServices.contentList().then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setContents(data.list);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Contents
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-header">
                                <div className="float-right">
                                    <Link to="/content/add">Add</Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <Loader isLoading={isLoading} />
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>Type</th>
                                                <th>Title</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contents && contents.map((btype, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>{btype.type}</td>
                                                        <td>{btype.title}</td>
                                                        <td>
                                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                                <Link to={`/content/update/${btype._id}`} type="button" className="btn btn-gradient-primary btn-sm">
                                                                    <i className="mdi mdi-lead-pencil"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentListController;