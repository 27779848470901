import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from '../common/Loader';
import UserSearch from './UserSearch';
import { displayDate } from "../../utils"
import Pagination from '../element/Pagintion';
import filePATH from "../../webroot/sample-file/user.xlsx";

function UserListComponent() {
    const [users, setUsers] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [totalUsers, setTotalUsers] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    useEffect(() => {
        getAllUsers({}, 1);
    }, [])
    const getAllUsers = (searchObj, pageNo = page) => {
        let obj = { ...searchObj, role: 'user', page: pageNo, limit: 50 }
        UserServices.allUserList(obj).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                setUsers(data.users);
                setTotalUsers(data.total);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const changeStatus = (evnt, userId) => {
        setIsLoading(true);
        let obj = {
            id: userId,
            status: evnt.target.checked
        }
        UserServices.userUpdateStatus(obj).then((responce) => {
            let { message, statusCode } = responce.data;
            if (statusCode == 200) {
                toast.success(message);
                getAllUsers({}, page);
            }
            else {
                toast.error(message);
                setIsLoading(false);
            }
        })
    }
    const inputHandler = (e) => {
        setFilterData({ ...filterData, [e.target.name]: e.target.value })
    }
    const searchFromSubmit = (e) => {
        if (filterData) {
            setIsLoading(true)
            getAllUsers(filterData, page);
        }
    }
    const handlePageClick = (n) => {
        setIsLoading(true);
        setUsers([]);
        setPage(n);
        getAllUsers({}, n);
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Users
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <UserSearch
                        total={totalUsers}
                        searchFrom={'Users'}
                        addURL={'/user/add'}
                        filterData={filterData}
                        inputHandler={inputHandler}
                        searchFromSubmit={searchFromSubmit}
                        from={"user"}
                        filePATH={filePATH}
                    />
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Email</th>
                                                <th>CreatedAt</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users && users.map((btype, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>{btype.firstName + " " + btype.lastName}</td>
                                                        <td>{btype.mobile}</td>
                                                        <td>{btype.email}</td>
                                                        <td>{displayDate(btype.createdAt)}</td>
                                                        <td>
                                                            {btype.status == true ?
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id)} className="switch-input" checked={btype.status || false} />
                                                                    <span className="switch-slider"></span>
                                                                </label> :
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id)} checked={btype.status || false} className="switch-input" />
                                                                    <span className="switch-slider"></span>
                                                                </label>
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                                <Link to={`/user/${btype._id}`} type="button" className="btn btn-gradient-info btn-sm">
                                                                    <i className="mdi mdi-lead-pencil"></i>
                                                                </Link>
                                                                <Link to={`/user/profile/${btype._id}`} type="button" className="btn btn-gradient-primary btn-sm">
                                                                    <i className="mdi mdi-eye"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination-container container">
                                    <Pagination onPaginate={handlePageClick} total={totalUsers} perPage={50} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserListComponent;