import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import UserSellerProfile from "./UserSellerProfile";
function UserProfileComponent() {
    let { userId } = useParams();
    let [userProf, setUserProf] = useState({});

    useEffect(() => {
        userDetail();
    }, [])
    const userDetail = async () => {
        UserServices.userDetail(userId).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                console.log(data);
                setUserProf(data.user);
            }
            else {
                toast.error(message);
            }
        })
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> User Profile
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <UserSellerProfile userProf={userProf} />
                                    </div>
                                    <div className="col-lg-9">
                                        <Tabs
                                            defaultActiveKey="wishList"
                                            transition={false}
                                            id="noanim-tab-example"
                                            className="mb-3"
                                        >
                                            <Tab eventKey="wishList" title="Wish list">
                                                Wish list
                                            </Tab>
                                            <Tab eventKey="orderHistory" title="Order History">
                                                Order History
                                            </Tab>
                                            <Tab eventKey="contact" title="Payments">
                                                Payments
                                            </Tab>
                                            <Tab eventKey="Projects" title="Projects">
                                                Projects
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserProfileComponent;