import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { UserServices } from "../../services";
import { userDetail } from "../../store/actions/allAction"
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
function ForgotPassword() {
    const history = useHistory();
    const [inputField, setInputField] = useState({
        email: ""
    })
    const [errField, setErrField] = useState({
        emailErr: ""
    })
    const crossRef = useRef();
    const disptch = useDispatch();
    useEffect(() => {
        const loggedInUser = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        if (loggedInUser && loggedInUser.user) {
            if (history) history.push('/home');
        }
    })
    const validForm = () => {
        let isFormValid = true;
        setErrField({
            emailErr: "",
        })
        if (inputField.email == "") {
            isFormValid = false;
            setErrField((prevState) => ({
                ...prevState,
                emailErr: 'Please enter email'
            }))
        }
        return isFormValid;
    }
    const inputHandler = (e) => {
        if (e.target.name == "email") {
            setErrField((prevState) => ({
                ...prevState,
                emailErr: ''
            }))
        }
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }
    const signinForm = async () => {
        if (validForm()) {
            UserServices.forgotPassword(inputField).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode == 200) {
                    toast.success(message);
                    if (history) history.push('/login');
                }
                else {
                    toast.error(message);
                }
            })
        }
    }
    return (
        <div className="app-body">
            <div className="login-container">
                <div className="card-group">
                    <div className="card p-4">
                        <div className="card-body card-body-pd">
                            <h1>Forgot Password</h1>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Enter user name.."
                                    name="email"
                                    value={inputField.email}
                                    onChange={inputHandler} />
                                <div className="invalid-feedback">
                                </div>
                            </div>
                            <div className="input-group">
                                <button onClick={signinForm} type="submit" value="submit"
                                    className="btn btn-gradient-info btn-rounded btn-fw login-btn-container">Forgot Password</button>
                            </div>
                            <div className="forgot-container">
                                <Link to={'/login'} className="forgot-txt">Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;