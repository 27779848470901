import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Select from 'react-select';
import { UserServices } from "../../services";
import { CATEGORY_TYPES } from "../../config/constant";
import Loader from '../common/Loader';
import BreadCrumb from '../common/BreadCrumb';
import filePATH from "../../webroot/sample-file/productexcel.xls";
import UploadFile from '../element/UploadFile';
import Pagination from '../element/Pagintion';
import RowLoader from '../element/RowLoader';
function ProductList() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isRowLoading, setIsRowLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [page, setPage] = useState(1);
    const [exportModal, setExportModal] = useState(false);
    const [filterData, setFilterData] = useState({});
    useEffect(() => {
        getProductList({}, 1);
    }, [])
    let { categoryId } = useParams();
    const getProductList = async (search, p = page) => {
        setIsLoading(true);
        await UserServices.productAdminList({ page: p, limit: 50, ...search }).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                setProducts(data.list);
                setTotalProducts(data.total)
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    };
    const changeStatus = (k, brandId, status) => {
        setIsRowLoading(true);
        UserServices.updateProduct({ productId: brandId, [k]: status }).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode === 200) {
                getProductList({}, page);
            }
            else {
                toast.error(message);
            }
            setIsRowLoading(false);
        })
    }
    const openModal = () => {
        setExportModal(true);
        document.body.classList.add('modal-open');
    }
    const closeModal = () => {
        setExportModal(false);
        document.body.classList.remove('modal-open')
    }
    const onSuccess = () => {
        closeModal();
        getProductList(filterData);
    }
    const inputHandler = (e) => {
        setFilterData({ ...filterData, [e.target.name]: e.target.value })
    }
    const searchFromSubmit = (e) => {
        if (filterData) {
            setIsLoading(true)
            getProductList(filterData, page);
        }
    }
    const handlePageClick = (n) => { setPage(n); getProductList(n); }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <BreadCrumb title={"Product List"} />
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin-sm">
                        <div className="card mt-card">
                            <div className="card-body card-body-pd row">
                                <div className="input-group col-lg-6">
                                    <input className="form-control"
                                        type="text"
                                        placeholder="Search by product name, vendor name"
                                        value={filterData.search || ""}
                                        name='search'
                                        onChange={inputHandler}
                                    />
                                    <span className="input-group-append">
                                        <button type="button"
                                            onClick={searchFromSubmit}
                                            className="btn btn-gradient-info btn-rounded btn-sm"
                                            value="submit">Search</button>
                                    </span>
                                </div>
                                <div className="float-right col-lg-6">
                                    <a class="btn btn-gradient-dark btn-sm btn-icon-text m-r-10" href={filePATH} download><i class="mdi mdi-cloud-download"></i> Download Sample File</a>
                                    <button onClick={() => openModal()} type="button" class="btn btn-gradient-primary btn-sm btn-icon-text m-r-10">
                                        <i class="mdi mdi-upload btn-icon-prepend"></i> Upload Excel
                                    </button>
                                    {/* <Link to="/faqs/add">
                                        <button type="button" class="btn btn-gradient-success btn-sm btn-icon-text">
                                            <i class="mdi mdi-message-plus"></i> Add FAQs
                                        </button>
                                    </Link> */}
                                    <UploadFile show={exportModal} filePATH={filePATH} onSuccess={onSuccess} from={"product"} closeModal={closeModal} title={'Import Product Excel'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th className="th-5">S.No.</th>
                                                <th className="th-20">Name</th>
                                                <th className="th-10">Model Number</th>
                                                <th className="th-10">Part Number</th>
                                                <th className="th-10">Original Price</th>
                                                <th className="th-10">Selling Price</th>
                                                <th className="th-10">Stock</th>
                                                <th className="th-10">Total Selling</th>
                                                <th className="th-10">Seller</th>
                                                <th className="th-5">Status</th>
                                                <th className="th-5">Feature</th>
                                                <th className="th-10">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products && products.map((btype, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>{btype.title}</td>
                                                        <td>{btype.modelNumber}</td>
                                                        <td>{btype.partNumber}</td>
                                                        <td>{btype.currency} {btype.originalPrice}</td>
                                                        <td>{btype.currency} {btype.sellingPrice}</td>
                                                        <td>{btype.totalUnits}</td>
                                                        <td>{btype.soldUnits}</td>
                                                        <td>{Object.keys(btype.vendor).length ? (btype.vendor.firstName + ' ' + btype.vendor.lastName) : "Admin"}</td>
                                                        <td>
                                                            {btype.status == true ?
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus('status', btype._id, false)} className="switch-input" checked={btype.status || false} />
                                                                    <span className="switch-slider"></span>
                                                                </label> :
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus('status', btype._id, true)} checked={btype.status || false} className="switch-input" />
                                                                    <span className="switch-slider"></span>
                                                                </label>
                                                            }
                                                        </td>
                                                        <td>
                                                            {btype.isFeatured == true ?
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus('isFeatured', btype._id, false)} className="switch-input" checked={btype.isFeatured || false} />
                                                                    <span className="switch-slider"></span>
                                                                </label> :
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus('isFeatured', btype._id, true)} checked={btype.isFeatured || false} className="switch-input" />
                                                                    <span className="switch-slider"></span>
                                                                </label>
                                                            }
                                                            {isRowLoading ? <RowLoader /> : null}
                                                        </td>
                                                        <td>
                                                            <Link className="btn btn-gradient-info btn-sm" to={`/product/${btype._id}`}><i className="mdi mdi-pencil"></i></Link>&nbsp;
                                                            <Link className="btn btn-gradient-primary btn-sm" to={`/product/detail/${btype._id}`}><i className="mdi mdi-eye"></i></Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="10">
                                                    <Pagination onPaginate={handlePageClick} total={totalProducts} perPage={50} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductList;