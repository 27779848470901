import { useEffect, useState } from 'react';
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import Loader from '../common/Loader';
import { Tabs, Tab } from 'react-bootstrap';
import RowLoader from '../element/RowLoader';
// targetUrl:
// displayAt:home
// key:fourth
const PromotionBanner = () => {
    const [banners, setBanners] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isBannerAdd, setIsBannerAdd] = useState(false);
    const [key, setKey] = useState('home');
    const [selectedFile, setSelectedFile] = useState();
    const [userForm, setUserForm] = useState([]);
    useEffect(() => {
        getAllBanners();
    }, [])
    const getAllBanners = () => {
        UserServices.promotionBannerList().then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setBanners(data);
                setUserForm(data);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const inputHandler = (k, i, e) => {
        if (e.target.files) {
            userForm[i][k]['image'] = (e.target.files[0]);
        }
        else {
            userForm[i][k][e.target.name] = e.target.value;
            setUserForm([...userForm]);
        }
    }
    const submitBanner = (k, d, i) => {
        setIsBannerAdd(true);
        let formVal = userForm[i][k];
        const formData = new FormData();
        formData.append('displayAt', d);
        formData.append('key', k);
        formData.append('targetUrl', formVal.targetUrl);
        formData.append('image', formVal.image);
        UserServices.addPromotionBanner(formData).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            setIsBannerAdd(false);
        })
    }

    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Promotion Banner
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card card-box">
                            <div className="card-body card-body-pd">
                                <Tabs defaultActiveKey={key} activeKey={key} onSelect={(k) => setKey(k)}>
                                    {banners && banners.map((b, i) => {
                                        return (
                                            <Tab eventKey={b.displayAt} title={b.displayAt.toUpperCase()}>
                                                <div className="promotion-caontainer">
                                                    <div className="promotion-continer form-group">
                                                        <label>First</label>
                                                        <div className="promotion-list">
                                                            <div class="promotion-img-view">
                                                                <img src={b.first && b.first.image} />
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group col-sm-4">
                                                                    <label for="image" class="required">File</label>
                                                                    <input type="file" name="image" onChange={(e) => inputHandler('first', i, e)} class="form-control" />
                                                                </div>
                                                                <div className="form-group col-sm-4">
                                                                    <label for="targetUrl" class="required">Target Url </label>
                                                                    <input type="text" name="targetUrl" onChange={(e) => inputHandler('first', i, e)} value={userForm && userForm.length && userForm[i].first && userForm[i].first.targetUrl} class="form-control" />
                                                                </div>
                                                                <div className="form-group col-sm-4">
                                                                    <button type="button" onClick={e => submitBanner('first', b.displayAt, i)} class="btn btn-gradient-info btn-fw">Submit {isBannerAdd ? <RowLoader /> : null}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        )
                                    })}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PromotionBanner;