import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import Chart from "react-apexcharts";
import UserSellerProfile from "../user/UserSellerProfile";
let chartData = {
    options: {
        chart: {
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: [2018, 2019, 2020, 2021, 2022]
        }
    },
    series: [{
        name: '',
        data: [0, 0, 0, 0, 0]
    }],
    animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    }
};
function SellerPofileComponent() {
    let { sellerId } = useParams();
    let [userProf, setUserProf] = useState({});
    let [analysis, setAnalysis] = useState({});
    let totalOrderedChart = { ...chartData };
    let totalOutForDeliveryChart = { ...chartData };
    let totalDeliveredChart = { ...chartData };
    let totalSalesChart = { ...chartData };
    let totalReturnChart = { ...chartData };
    let totalCancelChart = { ...chartData };
    useEffect(() => {
        userDetail();
    }, [])
    const userDetail = async () => {
        UserServices.venderProfile(sellerId).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setUserProf(data.user);
                setAnalysis(data.analysis);
                let { totalOrdered = 0, totalOutForDelivery = 0, totalDelivered = 0, totalSales = 0, totalReturn = 0, totalCancel = 0 } = data.analysis;
                totalOrderedChart = {
                    ...chartData,
                    series: [{
                        'data': [0, 0, 0, 0, totalOrdered]
                    }]
                };
                totalOutForDeliveryChart = {
                    ...chartData,
                    series: [{
                        'data': [0, 0, 0, 0, totalOutForDelivery]
                    }]
                };
                totalDeliveredChart = {
                    ...chartData,
                    series: [{
                        'data': [0, 0, 0, 0, totalDelivered]
                    }]
                };
                totalSalesChart = {
                    ...chartData,
                    series: [{
                        'data': [0, 0, 0, 0, totalSales]
                    }]
                };
                totalReturnChart = {
                    ...chartData,
                    series: [{
                        'data': [0, 0, 0, 0, totalReturn]
                    }]
                };
                totalCancelChart = {
                    ...chartData,
                    series: [{
                        'data': [0, 0, 0, 0, totalCancel]
                    }]
                };
            }
            else {
                toast.error(message);
            }
        })
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> Seller Profile
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <UserSellerProfile userProf={userProf} />
                                    </div>
                                    <div className="col-lg-9">
                                        <div className="seller-product-container">
                                            <div className="seller-product-content">
                                                <div className="heading-slr">
                                                    <h2>Total Orders</h2>
                                                    <h3>{analysis?.totalOrdered || 0}</h3>
                                                </div>
                                                <div className="graph-slr">
                                                    <Chart
                                                        options={totalOrderedChart.options}
                                                        series={totalOrderedChart.series}
                                                        type="bar"
                                                    />
                                                </div>
                                            </div>
                                            <div className="seller-product-content">
                                                <div className="heading-slr">
                                                    <h2>Total Out For Delivery</h2>
                                                    <h3>{analysis?.totalOutForDelivery || 0}</h3>
                                                </div>
                                                <div className="graph-slr">
                                                    <Chart
                                                        options={totalOutForDeliveryChart.options}
                                                        series={totalOutForDeliveryChart.series}
                                                        type="bar"
                                                    />
                                                </div>
                                            </div>
                                            <div className="seller-product-content">
                                                <div className="heading-slr">
                                                    <h2>Total Delivered</h2>
                                                    <h3>{analysis?.totalDelivered || 0}</h3>
                                                </div>
                                                <div className="graph-slr">
                                                    <Chart
                                                        options={totalDeliveredChart.options}
                                                        series={totalDeliveredChart.series}
                                                        type="bar"
                                                    />
                                                </div>
                                            </div>
                                            <div className="seller-product-content">
                                                <div className="heading-slr">
                                                    <h2>Total Sales</h2>
                                                    <h3>{analysis?.totalSales || 0}</h3>
                                                </div>
                                                <div className="graph-slr">
                                                    <Chart
                                                        options={totalSalesChart.options}
                                                        series={totalSalesChart.series}
                                                        type="bar"
                                                    />
                                                </div>
                                            </div>
                                            <div className="seller-product-content">
                                                <div className="heading-slr">
                                                    <h2>Total Return</h2>
                                                    <h3>{analysis?.totalReturn || 0}</h3>
                                                </div>
                                                <div className="graph-slr">
                                                    <Chart
                                                        options={totalReturnChart.options}
                                                        series={totalReturnChart.series}
                                                        type="bar"
                                                    />
                                                </div>
                                            </div>
                                            <div className="seller-product-content">
                                                <div className="heading-slr">
                                                    <h2>Total Cancel</h2>
                                                    <h3>{analysis?.totalCancel || 0}</h3>
                                                </div>
                                                <div className="graph-slr">
                                                    <Chart
                                                        options={totalCancelChart.options}
                                                        series={totalCancelChart.series}
                                                        type="bar"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <Tabs
                                            defaultActiveKey="Products"
                                            transition={false}
                                            id="noanim-tab-example"
                                            className="mb-3">
                                            <Tab eventKey="Products" title="Products">
                                                Products
                                            </Tab>
                                            <Tab eventKey="approvalRequests" title="approval requests">
                                                approval requests
                                            </Tab>
                                            <Tab eventKey="contact" title="Review and Ratings ">
                                                Review and Ratings
                                            </Tab>
                                            <Tab eventKey="RefundCancel" title="Refund/Cancel Withdraw request">
                                                Refund/Cancel Withdraw request
                                            </Tab>
                                            <Tab eventKey="View bids" title="View bids">
                                                View bids
                                            </Tab>
                                        </Tabs> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SellerPofileComponent;