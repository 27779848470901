import { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from '../common/Loader';
const formObj = {
    title: "",
    description: "",
    position: 0,
    image: ""
}
function BrandComponent() {
    const history = useHistory();
    const [inputField, setInputField] = useState(formObj);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [currentLocation, setCurrentLocation] = useState("brand");
    const [isLoading, setIsLoading] = useState(false);
    let { brandId } = useParams();
    let location = useLocation();
    useEffect(() => {
        let currentPATH = (location.pathname).split("/");
        setCurrentLocation(currentPATH[1]);
        brandDetail();
    }, [])
    const brandDetail = async () => {
        if (brandId) {
            setIsLoading(true);
            await UserServices.brandDetail({ brandId }).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode === 200) {
                    setInputField({ ...inputField, ...data });
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
    };
    const inputHandler = (e) => {
        if (e.target.files) {
            setSelectedFile(e.target.files[0]);
            setIsFilePicked(true);
        }
        else {
            setInputField({ ...inputField, [e.target.name]: e.target.value });
        }
    }
    const submitForm = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', inputField.title);
        formData.append('description', inputField.description);
        formData.append('position', inputField.position);
        formData.append('image', selectedFile);
        formData.append('type', currentLocation);
        if (inputField.title != "") {
            setIsLoading(true);
            if (brandId) {
                formData.append('brandId', brandId);
                UserServices.updateBrand(formData).then((responce) => {
                    let { message, statusCode } = responce.data;
                    if (statusCode == 200) {
                        toast.success(message);
                        setIsLoading(false);
                        if (history) history.push('/' + currentLocation);
                    }
                    else {
                        toast.error(message);
                    }
                })
            }
            else {
                if (isFilePicked) {
                    UserServices.addBrand(formData).then((responce) => {
                        let { message, statusCode } = responce.data;
                        if (statusCode == 200) {
                            toast.success(message);
                            if (history) history.push('/' + currentLocation);
                        }
                        else {
                            toast.error(message);
                        }
                        setIsLoading(false);
                    })
                }
            }
        }
        else {
            toast.error('Please fill all the required fields')
        }
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span>Manage {currentLocation}
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card card-box">
                            <div className="card-body card-body-pd">
                                <form encType="multipart/form-data">
                                    <div className="form-horizontal">
                                        <div className="row">
                                            <div className="form-group col-sm-5">
                                                <label htmlFor="title" className="required">Title</label>
                                                <input type="text" name="title"
                                                    value={inputField.title}
                                                    onChange={inputHandler}
                                                    className="form-control" id="title" required />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="position">Position</label>
                                                <input type="text" name="position"
                                                    value={inputField.position}
                                                    onChange={inputHandler}
                                                    className="form-control" id="position" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="description">Description</label>
                                                <input type="text" name="description"
                                                    value={inputField.description}
                                                    onChange={inputHandler}
                                                    className="form-control" id="description" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="image" className="required">Image</label>
                                                <input type="file" name="image"
                                                    onChange={inputHandler}
                                                    className="form-control" id="image" required />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" onClick={submitForm} className="btn btn-gradient-info btn-fw">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BrandComponent;