import InterceptorService from './InterceptorService'
export default {
    ...InterceptorService,
    dashboard() {
        return this.get(`admin/dashboard`);
    },
    categoriesList(type, page = 1, limit = 50) {
        return this.get(`categories/list?type=${type}&page=${page}&limit=${limit}`);
    },
    countryList() {
        return this.get(`user/country`);
    },
    categoriesDropDownList(type) {
        return this.get(`categories/dropdown/list?type=${type}`);
    },
    subCategoriesDropDownList(type) {
        return this.get(`categories/dropdown/list/sub?type=${type}`);
    },
    addCategories(body) {
        return this.post(`categories/add`, body);
    },
    updateCategories(body) {
        return this.post(`categories/update`, body);
    },
    updateCategoryStatus(body) {
        return this.post(`categories/update/status`, body);
    },
    categoryDetail(categoryId) {
        return this.get(`categories/detail?categoryId=${categoryId}`);
    },
    businessList() {
        return this.get(`business/list`);
    },
    addBusinessType(body) {
        return this.post(`business/add`, body);
    },
    brandList(type = "brand") {
        return this.get(`brand/list?type=${type}`);
    },
    brandDropdownList(type = "brand") {
        return this.get(`brand/dropdown/list?type=${type}`);
    },
    addBrand(body) {
        return this.post(`brand/add`, body);
    },
    updateBrand(body) {
        return this.post(`brand/update`, body);
    },
    brandDetail(body) {
        return this.post(`brand/detail`, body);
    },
    bannerList() {
        return this.get(`banner/list`);
    },
    promotionBannerList() {
        return this.get(`banner/pramotion/list`);
    },
    bannerDetail(body) {
        return this.post(`banner/detail`, body);
    },
    dropdownList(body) {
        return this.post(`banner/dropdown/list`, body);
    },
    addBanner(body) {
        return this.post(`banner/add`, body);
    },
    updateBanner(body) {
        return this.post(`banner/update`, body);
    },
    addUser(body) {
        return this.post(`user/add`, body);
    },
    updateUser(body) {
        return this.post(`user/update`, body);
    },
    userUpdateStatus(body) {
        return this.post(`user/update/status`, body);
    },
    userDetail(userId) {
        return this.get(`user/detail?userId=${userId}`);
    },
    userGet(userId) {
        return this.get(`user/get?userId=${userId}`);
    },
    venderProfile(userId) {
        return this.get(`user/vendor/detail?userId=${userId}`);
    },
    allUserList(body) {
        return this.post(`user/list`, body);
    },
    allVenderList(body) {
        return this.post(`user/vendor/list`, body);
    },
    login(body) {
        return this.post(`admin/login`, body);
    },
    forgotPassword(body) {
        return this.post(`admin/forgot/password`, body);
    },
    updatePassword(body) {
        return this.post(`admin/update/password`, body);
    },
    profileUpdate(body) {
        return this.post(`admin/profile/update`, body);
    },
    adminDetail(body) {
        return this.post(`admin/detail`, body);
    },
    contentList() {
        return this.get(`content/list`);
    },
    addContent(body) {
        return this.post(`content/add`, body);
    },
    updateContent(body) {
        return this.post(`content/update`, body);
    },
    contentDetail(body) {
        return this.post(`content/detail`, body);
    },
    addFAQs(body) {
        return this.post(`faq/add`, body);
    },
    updateFAQs(body) {
        return this.post(`faq/update`, body);
    },
    detailFAQs(faqsId) {
        return this.get(`faq/detail?faqsId=${faqsId}`);
    },
    faqsList() {
        return this.get(`faq/list`);
    },
    contactusList(body) {
        return this.post(`contact/list`, body);
    },
    replyContactus(body) {
        return this.post(`contact/reply`, body);
    },
    productAdminList(body) {
        return this.post(`product/admin/list`, body);
    },
    reviewList(ratingFor) {
        return this.get(`review/list?approvedStatus=&ratingFor=${ratingFor}&status=`);
    },
    addProduct(body) {
        return this.post(`product/add`, body);
    },
    editProduct(body) {
        return this.post(`product/edit`, body);
    },
    updateProduct(body) {
        return this.post(`product/update`, body);
    },
    myProduct(body) {
        return this.post(`product/my/list`, body);
    },
    productAllList(body) {
        return this.post(`product/all/list`, body);
    },
    productDetail(productId) {
        return this.get(`product/detail?productId=${productId}`);
    },
    importExcelFile(body, lurl) {
        return this.post(`import/${lurl}`, body);
    },
    buyProductList(body) {
        return this.post(`buyproduct/admin/order/list`, body);
    },
    serviceAdminList(body) {
        return this.post(`service/list/a-all`, body);
    },
    addService(body) {
        return this.post(`service/add`, body);
    },
    editService(body) {
        return this.post(`service/edit`, body);
    },
    updateService(body) {
        return this.post(`service/update`, body);
    },
    updateServiceImages(body) {
        return this.post(`service/update/images`, body);
    },
    serviceUpdateStatus(body) {
        return this.post(`service/update/status`, body);
    },
    addPromotionBanner(body) {
        return this.post(`banner/pramotion/add`, body);
    },
    buyProductOrderDetail(id) {
        return this.get(`buyproduct/order-details/${id}`);
    },
    buyproductChangeStatus(body) {
        return this.post(`buyproduct/change-status`, body);
    },
    newsletterSubscriberList() {
        return this.get(`newsletter/list`);
    },
    sendMail(body){
        return this.post(`newsletter/send-mails`, body);
    }
}