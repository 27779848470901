import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from '../common/Loader';
import UploadFile from "../element/UploadFile";
import filePATH from "../../webroot/sample-file/brandexcel.xls";
function BrandListComponent() {
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentLocation, setCurrentLocation] = useState("brand");
    const [inputField, setInputField] = useState({
        title: ""
    });
    let location = useLocation();
    let currentPATH = (location.pathname).replace("/", "");
    useEffect(() => {
        setCurrentLocation(currentPATH);
        getAllBrands(currentPATH);
    }, [])
    const [exportModal, setExportModal] = useState(false);
    const openModal = () => {
        setExportModal(true);
        document.body.classList.add('modal-open');
    }
    const closeModal = () => {
        setExportModal(false);
        document.body.classList.remove('modal-open')
    }
    const onSuccess = () => {
        closeModal();
        getAllBrands(currentPATH);
    }
    const getAllBrands = (type) => {
        UserServices.brandList(type).then((responce) => {
            let { data, message, statusCode } = responce.data;
            if (statusCode == 200) {
                setBrands(data.list);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const changeStatus = (evnt, brandId) => {
        setIsLoading(true);
        let obj = {
            brandId: brandId,
            status: evnt.target.checked
        }
        UserServices.updateBrand(obj).then(async (responce) => {
            let { message, statusCode } = responce.data;
            if (statusCode == 200) {
                toast.success(message);
                getAllBrands();
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-info text-white mr-2">
                            <i className="mdi mdi-home"></i>
                        </span> {currentLocation}
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview <i
                                    className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row">
                    <div className="col-lg-12 grid-margin">
                        <div className="card mt-card">
                            <div className="card-header">
                                <div className="float-right">
                                    {currentLocation == 'brand' ? <>
                                        <a class="btn btn-gradient-dark btn-sm btn-icon-text m-r-10" href={filePATH} download><i class="mdi mdi-cloud-download"></i> Download Sample File</a>
                                        <UploadFile show={exportModal} filePATH={filePATH} onSuccess={onSuccess} from={"brand"} closeModal={closeModal} title={'Import Brand Excel'} />
                                        <button type="button" onClick={() => openModal()} class="btn btn-gradient-primary btn-sm btn-icon-text m-r-10">
                                            <i class="mdi mdi-upload btn-icon-prepend"></i> Upload Excel
                                        </button>
                                    </> : null}
                                    <Link to={`/${currentLocation}/add`}>
                                        <button type="button" class="btn btn-gradient-success btn-sm btn-icon-text">
                                            <i class="mdi mdi-message-plus"></i> Add
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mat-table">
                                        <thead>
                                            <tr className="mat-header">
                                                <th>S.No.</th>
                                                <th>Image</th>
                                                <th>Title</th>
                                                <th>Slug</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {brands && brands.map((btype, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td><img src={btype.image ? btype.image : ""} /></td>
                                                        <td>{btype.title}</td>
                                                        <td>{btype.slug}</td>
                                                        <td>
                                                            {btype.status == true ?
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id)} className="switch-input" checked={btype.status || false} />
                                                                    <span className="switch-slider"></span>
                                                                </label> :
                                                                <label className="switch switch-3d switch-primary grid-switch-1">
                                                                    <input type="checkbox" onChange={(evnt) => changeStatus(evnt, btype._id)} checked={btype.status || false} className="switch-input" />
                                                                    <span className="switch-slider"></span>
                                                                </label>
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                                <Link to={`/${currentLocation}/update/${btype._id}`} type="button" className="btn btn-gradient-primary btn-sm">
                                                                    <i className="mdi mdi-lead-pencil"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BrandListComponent;