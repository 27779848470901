import { useState } from "react";
import { toast } from "react-toastify";
import { UserServices } from "../../services";
function ChangePassword({ loginUser }) {
    const [inputField, setInputField] = useState({
        newPassword: "",
        password: ""
    })
    const [errField, setErrField] = useState({
        newPasswordErr: "",
        passwordErr: ""
    })
    const validForm = () => {
        let isFormValid = true;
        setErrField({
            newPasswordErr: "",
            passwordErr: ""
        })
        if (inputField.password == "") {
            isFormValid = false;
            setErrField((prevState) => ({
                ...prevState,
                password: 'Please enter password'
            }))
        }
        if (inputField.newPassword == "") {
            isFormValid = false;
            setErrField((prevState) => ({
                ...prevState,
                newPassword: 'Please enter new password'
            }))
        }
        return isFormValid;
    }

    const inputHandler = (e) => {
        if (e.target.name == "newPassword") {
            setErrField((prevState) => ({
                ...prevState,
                newPasswordErr: ''
            }))
        }
        if (e.target.name == 'password') {
            setErrField((prevState) => ({
                ...prevState,
                passwordErr: ''
            }))
        }
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }
    const signinForm = async () => {
        if (validForm()) {
            inputField.id = loginUser._id;
            UserServices.updatePassword(inputField).then((responce) => {
                let { data, message, statusCode } = responce.data;
                if (statusCode == 200) {
                    toast.success(message);
                }
                else {
                    toast.error(message);
                }
            })
        }
    }
    return (
        <div className="form-horizontal">
            <div className="row">
                <div className="form-group col-sm-6">
                    <label htmlFor="password">Old Password</label>
                    <input type="text" name="password"
                        value={inputField.password}
                        onChange={inputHandler}
                        className="form-control"
                        id="password" />
                </div>
                <div className="form-group col-sm-6">
                    <label htmlFor="newPassword">New Password</label>
                    <input type="text" name="newPassword"
                        value={inputField.newPassword}
                        onChange={inputHandler}
                        className="form-control"
                        id="newPassword" />
                </div>
            </div>
            <button onClick={signinForm} className="btn btn-gradient-success">Submit</button>
        </div>
    );
}

export default ChangePassword;