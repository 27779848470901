import ReactPaginate from 'react-paginate';
function Pagination({ total, perPage = 10, onPaginate }) {
    const handlePageClick = (selectedPage) => { onPaginate(selectedPage.selected+1); }
    const pageCount = Math.ceil(total / perPage);
    return (
        <ReactPaginate
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            subContainerClassName={"pages pagination"}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            breakLabel="..."
            nextLabel=" >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
        />
    )
}
export default Pagination;